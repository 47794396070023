import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { BASE_URL } from '../config'
import { useSnackbar } from 'material-ui-snackbar-provider'; 

const PasswordChange = () => {
  const snackbar = useSnackbar(); 

  const [passwordData, setPasswordData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswordData({ ...passwordData, [name]: value });
  };

  const handleSubmit = async(e) => {


    e.preventDefault();

    let token = localStorage.getItem("token");

            try{const axiosRes = await axios({
                method: "PATCH",
                headers: { Authorization: `Bearer ${token}` },
                url: `${BASE_URL}/admin/change-password`,
                data: passwordData
            });
            console.log(axiosRes,"axiosss")
    
            if(axiosRes.data.statusCode===200){

                snackbar.showMessage('Password changed successfully');

                setPasswordData({
                    currentPassword: '',
                    newPassword: '',
                    confirmPassword: '',
                  });
                
            }}catch(err){
                console.log(err,"data")
                snackbar.showMessage(err.response.data.message);

            }

  
};

  return (
    <div className="password-change-page">
      <h1 className="verification_text">Change Password</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="currentPassword">
          <Form.Label>Current Password</Form.Label>
          <Form.Control
            className="form-control chng-pwd" 
            type="password"
            name="currentPassword"
            value={passwordData.currentPassword}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="newPassword">
          <Form.Label>New Password</Form.Label>
          <Form.Control
            className="form-control chng-pwd" 
            type="password"
            name="newPassword"
            value={passwordData.newPassword}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="confirmPassword">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            className="form-control chng-pwd" 
            type="password"
            name="confirmPassword"
            value={passwordData.confirmPassword}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <button
          className="btnblack_new btn_chng_pwd" 
        
          type="submit"
        >
          Change Password
        </button>
      </Form>
    </div>
  );
};

export default PasswordChange;
