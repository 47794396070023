// // UserLogs.js
// import React, { useEffect, useState } from 'react';
// import { BASE_URL } from "../config";
// import axios from 'axios';


// function AdminLogs() {
//   const [logs, setLogs] = useState([]);

//   useEffect(() => {
//     (async()=>{

//         const data = await  axios.get(`${BASE_URL}/admin/admin-logs/`)
//         console.log(data.data.success_res)
//         setLogs(data.data.success_res)

//   })()

//     // fetch(`${BASE_URL}/admin/admin-logs/`) // Replace with your backend endpoint
//     //   .then((response) => response.json())
//     //   .then((data) => setLogs(data))
//     //   .catch((error) => console.error('Error fetching logs:', error));
//     //   console.log(logs,"logs")
//   }, []);

//   return (
//     <div className="user-logs">
//       <h1>User Logs</h1>
//       <ul>
//         {logs.map((log, index) => (
//           <li key={index}>{new Date(log.time).toLocaleString()}</li>
//         ))}
//       </ul>
//     </div>
//   );
// }

// export default AdminLogs;



// ================================= 


import React, { useContext, useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { RiDeleteBin5Line } from "react-icons/ri";
import { AiOutlineCloseCircle, AiFillCheckCircle, AiOutlineCheck } from "react-icons/ai";
import { BsArrowUpRight } from "react-icons/bs";
import { Form, InputGroup } from 'react-bootstrap'
import { CgSearch } from 'react-icons/cg';


import { Mcontext } from '../context/Mcontext';
import { BASE_URL } from '../config';
import { useSnackbar } from 'material-ui-snackbar-provider';
import axios from 'axios';
import Loader from '../components/Loader';
import { Link } from 'react-router-dom';


const AdminLogs = ({isDashboard}) => {
    const snackbar = useSnackbar();

    const {
        adminState,
        adminDispatch,
    } = useContext(Mcontext);
    const [loading, setLoading] = useState(false);
    const [totalPage, setTotalPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const [searchInput, setSearchInput] = useState('');
    const [sortOption, setSortOption] = useState('-time'); 
    
      const [logs, setLogs] = useState([]);


    useEffect(() => {
        loadLogs();
    }, [currentPage,sortOption,searchInput])

    




    const loadLogs = async () => {
        if(!searchInput){

            setLoading(true);
        }
        let summaryData;
        
        let token = localStorage.getItem('token');

        try{

            summaryData = await  axios({method:'GET',
            headers: { Authorization: `Bearer ${token}` },
            url:`${BASE_URL}/admin/admin-logs?page=${currentPage}&limit=15&sortBy=${sortOption}`})
    
    
            setLogs(summaryData?.data?.success_res?.logs)
            setTotalPage(summaryData?.data?.success_res?.totalPages);
    
            if (summaryData?.data?.success) {
                setLoading(false);
            } else {
                //console.log("error blaock")
                adminDispatch({ type: "ERROR", payload: summaryData?.response.data.message });
                setLoading(false);
            }
        }catch(err){
            setLoading(false);
            snackbar.showMessage(err.response.data.message);

            console.log(err.response.data.message)
        }
    }


    const chageCurrentPage = (pageType) => {

        if (pageType == "prev") {
            if (currentPage == 1) {
                alert("No Page Exist Before 1")
            } else {
                setCurrentPage(currentPage - 1);
            }
        }

        if (pageType == "next") {
            if (currentPage == totalPage) {
                alert("No Page Exist After ")
            } else {
                setCurrentPage(currentPage + 1);
            }

        }

    }


    if (loading) { return <Loader /> }

    return (
        <>

            <div className="verification_div">
            {/* {isDashboard ? ( */}
                    <h4 className='verification_text'>Admin Logs</h4>
                {/* ) : ( */}
                    {/* <h1 className="verification_text">Users</h1> */}
                {/* )} */}
            </div>

            {
    !isDashboard?
  <div className='search_sort_div posabs search-sort'>
                    {/* <div className='search_bar'>
                        <InputGroup className="mb-3 posrel">
                            <div className='search_icon'>
                                <CgSearch color='var(--primary)' size={20} />
                            </div>
                            <Form.Control
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="basic-addon1"
                                value={searchInput}
                                onChange={(e) => setSearchInput(e.target.value)}
                            />
                        </InputGroup>
                    </div> */}
                    <div className='sortby_select'>
                        <Form.Select aria-label="Default select example"  value={sortOption} onChange={(e) => setSortOption(e.target.value)}>
                        <option value="-time">Sort by Date (Newest First)</option>
      <option value="time">Sort by Date (Oldest First)</option>
      {/* <option value="-totalEarnings">Sort by Total Earnings (Descending)</option>
      <option value="totalEarnings">Sort by Total Earnings (Ascending)</option> */}
                        </Form.Select>
                    </div>
                </div>
  :''
  }
            <div className='user_panel'>
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Time</th>
                            <th>Email</th>
                            <th>Admin Ip</th>
                            <th>Admin Browser</th>
                            <th>   </th>
                        </tr>
                    </thead>

                    <tbody>
                        {
                            logs && logs.map((log, index) => (
                                <>
                                    <tr>
                                        {/* <td><Link to={`/userdetails/${user._id}`}>{user.name}</Link></td> */}
                                        {/* <td>{user.email ? user.email : "not provided"}</td> */}
                                        <td>{log?.userId?.name}</td>
                                        <td>{new Date(log.time).toLocaleString()}</td>
                                        <td>{log?.userId?.email ? log?.userId?.email : "not provided"}</td>
                                        <td>{log?.userIp}</td>
                                        <td>{log?.browser}</td>
                                        
                                        <td>
                                            <div className="edit">
                                        </div></td>
                                    </tr>
                                </>
                            ))
                        }

                    </tbody>
                </Table>
                <div className='pagination-div'>
                    <button className='btn btn-light' onClick={() => chageCurrentPage('prev')}>Previous</button>
                    <span> Page {currentPage} of {totalPage} </span>
                    <button className='btn btn-light' onClick={() => chageCurrentPage('next')}>Next</button>
                </div>
            </div>

        </>
    )
}

export default AdminLogs