import Header from "./header";
import SideBar from "./sideBar";
// import Footer from "./footer";
import { Outlet } from "react-router-dom";
import { useState } from "react";

const FrontendPanel = () => {
const [Show, setShow] = useState('dashboard-wrapper')
    return(<>
    <div className="dashboard-block">
        <div className={Show}>
            <Header/>
            <div className="dashboardsidebar">
                <SideBar/>
            </div>
            <div className="main-body">
                <Outlet/>
            </div>

        </div>
    </div>
    </>)

}

export default FrontendPanel