import React, { useEffect, useState, useContext } from "react";
import { Table, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Mcontext } from "../../context/Mcontext";
import Loader from "../Loader";

const FriendsList = () => {
  const { userid } = useParams();
  const { getFriendList } = useContext(Mcontext);
  const [loading, setLoading] = useState(true);
  const [sortBy, setSortBy] = useState("");
  const [friends, setFriends] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    loadFriends();
  }, [currentPage, sortBy]);

  const loadFriends = async () => {
    setLoading(true);
    const friendListRes = await getFriendList(
      userid,
      currentPage,
      limit,
      sortBy
    );
    console.log("🚀 ~ loadFriends ~ friendListRes:", friendListRes);

    if (friendListRes?.success) {
      setLoading(false);
      setFriends(friendListRes?.success_res?.docs);
      setTotalPage(friendListRes?.success_res?.pages);
    } else {
      setLoading(false);
    }
  };

  const changeCurrentPage = (pageType) => {
    if (pageType === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
    if (pageType === "next" && currentPage < totalPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleSortChange = (event) => {
    const newSortBy = event.target.value;
    setSortBy(newSortBy);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="main_div_border">
      <h5 className="main_heading_box">Friend List</h5>
      <div className="sortby_select">
        <Form.Select
          aria-label="Default select example"
          onChange={handleSortChange}
          value={sortBy}
        >
          <option value="">Sort By</option>
          <option value="nameAsc">Name (A-Z)</option>
          <option value="nameDesc">Name (Z-A)</option>
        </Form.Select>
      </div>
      <div className="user_panel ">
        <Table responsive>
          <thead>
            <tr>
              <th>Friend Image</th>
              <th>Name</th>
              <th>Phone</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {friends?.length === 0 && (
              <tr>
                <td colSpan="2">No Friends!</td>
              </tr>
            )}
            {friends?.map((friend, index) => (
              <tr key={index}>
                <td>
                  <img src={friend?.friendUserId?.photo} />
                </td>
                <td>{friend?.friendUserId?.name}</td>
                <td>{friend?.friendUserId?.phone}</td>
                <td>{friend?.friendUserId?.email}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <div className="pagination_box">
        <div className="pagination-div">
          <button
            className="btn btn-light"
            onClick={() => changeCurrentPage("prev")}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span>
            {" "}
            Page {currentPage} of {totalPage}{" "}
          </span>
          <button
            className="btn btn-light"
            onClick={() => changeCurrentPage("next")}
            disabled={currentPage === totalPage}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default FriendsList;
