import React, { useState, useEffect, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import ViewPayoutHistory from "../../components/BoxComponents/ViewPayoutHistory";
import { FaUserFriends } from "react-icons/fa";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import RequestHistory from "../../components/BoxComponents/RequestHistory";
import CheckinHistory from "../../components/BoxComponents/CheckinHistory";
import FriendsListAll from "../../components/BoxComponents/friendList";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";
import { useSnackbar } from "material-ui-snackbar-provider";
import { BASE_URL } from "../../config";
import { Mcontext } from "../../context/Mcontext";
import { useParams, Link } from "react-router-dom";
import Transactions from "../../components/BoxComponents/Transactions";
import { getPayoutRequestsByUserId } from "../../context/functions/AdminFunction";

const UserDetails = () => {
  const snackbar = useSnackbar();

  let { userid } = useParams();
  // //console.log(userid);

  const { adminState, adminDispatch, getSingleUser, getUserCurrentCheckin } =
    useContext(Mcontext);
  const { totalVenues } = adminState;

  console.log("userId", userid);

  const [loading, setLoading] = useState(false);
  const [userDetail, setUserDetail] = useState({});
  const [currentCheckIn, setCurrentCheckIn] = useState({});
  const [friendList, setFriendList] = useState([]);

  const getUserDetails = () => {
    getPayoutRequestsByUserId(userid)
      .then((data) => {
        console.log("Data from user da", data);
      })
      .catch((error) => {
        console.log("error while getting user info", error);
      });
  };

  useEffect(() => {
    if (userid) {
      loadSingleUser();
      getCheckIn();
      getUserDetails();
    }
  }, []);

  const loadSingleUser = async () => {
    setLoading(false);
    try {
      // console.log("user id ", userid);
      let userRes = await getSingleUser(userid);
      if (userRes?.success) {
        setUserDetail(userRes?.success_res);
        setFriendList(userRes?.success_res?.friends);
        setLoading(false);
        // toast("hello")
        // snackbar.showMessage(userRes?.message);
      } else {
        setLoading(false);
        // snackbar.showMessage(userRes?.message);
      }
    } catch (error) {
      console.log(error, "Error in fetching user");
    }
  };

  const getCheckIn = async () => {
    let checkInRes = await getUserCurrentCheckin(userid);
    if (checkInRes?.success) {
      setCurrentCheckIn(checkInRes?.success_res);
    } else {
      let checkin = {};
      checkin.venueId = {
        title: "Not Checked IN",
        venuePhoto: "null",
      };
      setCurrentCheckIn(checkin);
    }
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <div className="body-main">
        <div className="title-bar">
          <h2>
            <Link to="/users">
              <HiOutlineArrowNarrowLeft />
            </Link>
            &nbsp;User Details
          </h2>
        </div>

        <div className="clubbar">
          <div className="clubleft">
            <h4>{userDetail?.user?.name}</h4> &nbsp;
            <h6 className="color_primary">
              <FaUserFriends color="#8C56FF" /> &nbsp;{" "}
              {userDetail?.totalFriends} Friends
            </h6>
          </div>
        </div>

        <div className="d-flex align-items-center flex-wrap">
          <div className="venuebox me-5">
            <h5>Profile Picture</h5>
            <div className="profilepic">
              <img src={userDetail?.user?.photo} alt="icon" />
            </div>
          </div>
          <div className="venuebox me-5">
            <h5>Banner Image</h5>
            <div className="venue-media">
              <img src={userDetail?.user?.coverphoto} alt="icon" />
            </div>
          </div>
          <div className="venuebox">
            <h5>Currently CheckedIn At</h5>
            <p>{currentCheckIn?.venueId?.title}</p>
            <img
              src={currentCheckIn?.venueId?.venuePhoto}
              alt="icon"
              height={75}
              width={75}
            />
          </div>
        </div>

        {/* <div className="venuebox">
          <h5>Location</h5>
          <p>New York</p>
        </div> */}
        <div className="venuebox">
          <h5>Email</h5>
          <p>{userDetail?.user?.email}</p>
          <h5>Phone</h5>
          <p>{userDetail?.user?.phone}</p>
          <h5>Wallet Balance</h5>
          <p>{userDetail?.user?.walletBalance}</p>
        </div>
        <div className="venue_detail_boxes">
          <Row>
            <Col md={12}>
              <FriendsListAll friendList={friendList} />
            </Col>
            <Col md={12}>
              <ViewPayoutHistory />
            </Col>
            <Col md={12} className="mt-5">
              <Transactions />
            </Col>
            <Col md={12} className="mt-5">
              <RequestHistory />
            </Col>
            <Col md={12} className="mt-5">
              <CheckinHistory />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default UserDetails;
