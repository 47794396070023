import React, { useContext, useEffect } from 'react'
import '../css/style.css'
import { BsPlusCircleFill } from "react-icons/bs";
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Header from '../components/FrontendPanel/header';
import { Mcontext } from '../context/Mcontext';
import { BASE_URL } from '../config';
import { useSnackbar } from 'material-ui-snackbar-provider'
import Loader from '../components/Loader';


let currentImage = "";
let venueId = "";

const Verification = () => {
  const snackbar = useSnackbar()
  const {
    adminState,
    adminDispatch,
    getSummary,
    getAllVenues,
    toogleVenueRequest,
  } = useContext(Mcontext);
  const { adminError, totalVenues } = adminState;

  const [loading, setLoading] = useState(false);
  const [totalVenuePage, setTotalVenuePage] = useState(0);
  const [currentVenuePage, setCurrentVenuePage] = useState(1);
  const [rejectmodalShow, setRejectModalShow] = useState(false);
  const [imagemodalShow, setImageModalShow] = useState(false);

  useEffect(() => {
    loadVenues();
  }, [currentVenuePage])

  const RejectModal = (props) => {
    return (
      // Reject mondal 
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Are you sure you want to Reject the request?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className='buttons_modal'>
            <button onClick={() => toggleRequest("reject")} className="approve" variant="primary" centered>
              Yes
            </button>
            <button onClick={() => toggleRequest("cancel")} className="reject" variant="primary" centered>
              Cancel
            </button>
          </div>

        </Modal.Body>
      </Modal>

    );
  }

  const ImageModal = (props) => {
    return (
      // Image mondal 
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          {/* <p>{props.img}</p> */}
          <div className='modal_image'>
            <img src={currentImage} /> </div>
        </Modal.Body>
      </Modal>
    );
  }

  const loadVenues = async () => {
    // snackbar.showMessage("hello from snackbar");
    setLoading(true);
    const summaryData = await getAllVenues(currentVenuePage);
    if (summaryData.success) {
      adminDispatch({ type: "VENUES_LOADED", payload: summaryData.success_res.venues });
      setTotalVenuePage(summaryData.success_res.totalPages);
      setLoading(false);
    } else {
      setLoading(false);
      adminDispatch({ type: "ERROR", payload: summaryData.response.data.message })
    }
  }

  const openImageModal = (imgSrc) => {
    currentImage = imgSrc;
    setImageModalShow(true)
  }

  const setVenueId = (id) => {
    venueId = id;
    toggleRequest("approve");
  }

  const toggleRequest = async (type) => {
    //console.log(type);
    if (type === "cancel") {
      setRejectModalShow(false);
      snackbar.showMessage("No Action Taken");
    }

    if (type === "approve") {
      const resData = await toogleVenueRequest(venueId, type);
      if (resData.success) {
        setRejectModalShow(false);
        // adminDispatch({ type: "VENUE_UPDATED", payload: resData.success_res});
        loadVenues();
        snackbar.showMessage(resData.message.toUpperCase());
      }

    }
    if (type === "reject") {
      const resData = await toogleVenueRequest(venueId, type);
      if (resData.success) {
        setRejectModalShow(false);
        snackbar.showMessage(resData.message.toUpperCase());
      }

    }
    setRejectModalShow(false);
  }

  const openRejectModal = (venueid) => {
    venueId = venueid;
    setRejectModalShow(true);
  }

  const chageCurrentPage = (pageType) => {

    if (pageType == "prev") {
      if (currentVenuePage == 1) {
        alert("No Page Exist Before 1")
      } else {
        setCurrentVenuePage(currentVenuePage - 1);
      }
    }

    if (pageType == "next") {
      if (currentVenuePage == totalVenuePage) {
        alert("No Page Exist After ")
      } else {
        setCurrentVenuePage(currentVenuePage + 1);
      }

    }

  }

  if (loading) { return <Loader /> }

  return (
    <>
      <div className="verification_div">
        <h1 className="verification_text">Verification Request</h1>
      </div>
      <RejectModal
        show={rejectmodalShow}
        onHide={() => setRejectModalShow(false)}
      />
      <ImageModal
        show={imagemodalShow}
        //img={"image"}
        onHide={() => setImageModalShow(false)}
      />
      <div className='verification_panel'>
        <Table responsive>
          <thead>
            <tr>
              <th>Venue Name</th>
              <th>Type</th>
              <th>Address</th>
              <th>Registered Phone No.</th>
              <th>Document</th>
              <th>   </th>
            </tr>
          </thead>

          <tbody>
            {totalVenues && totalVenues.map((venue, index) => (
              <>
                {
                  !venue.verifiedAt &&
                  <>
                    <tr>
                      <td>{venue.title}</td>
                      <td>{venue.type}</td>
                      <td>{venue.address}</td>
                      <td>{venue.phoneNumber}</td>
                      <td>
                        <div className='document_img'>
                          {
                            venue.docVerify ?
                              <>
                                <div >
                                  <img className='crd_img' src={venue.docVerify} alt='venue doc' />
                                </div>
                                <a onClick={() => openImageModal(venue.docVerify)}>View Image</a>
                              </> : "No Image"
                          }

                          {/* <p>{`${BASE_URL}/`+venue.docVerify}</p> */}

                        </div>
                      </td>
                      <td><div className="edit">
                        <button onClick={() => setVenueId(venue._id)} className="approve">Approve</button>
                        <button onClick={() => openRejectModal(venue._id)} className="reject">Reject</button>
                      </div></td>
                    </tr>
                  </>

                }
              </>
            ))

            }

          </tbody>
        </Table>
        <div className='pagination-div'>
          <button className='btn btn-light' onClick={() => chageCurrentPage('prev')}>Previous</button>
          <span> Page {currentVenuePage} of {totalVenuePage} </span>
          <button className='btn btn-light' onClick={() => chageCurrentPage('next')}>Next</button>
        </div>

      </div>

    </>
  )
}

export default Verification