import React, { useState, useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Mcontext } from '../context/Mcontext';
import { BASE_URL } from '../config';
import '../css/style.css'
import { BsFileEarmarkMusic, BsPlusCircleFill } from "react-icons/bs";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import Loader from '../components/Loader';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { useSnackbar } from 'material-ui-snackbar-provider'



import Venue from './Venue';

const RevenueSplits = () => {
  const snackbar = useSnackbar();
  const {
    adminState,
    adminDispatch,
    getRevenue,

  } = useContext(Mcontext);
  const { revenueStats } = adminState;

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [revenue, setRevenue] = useState({
    venueSplit: 0,
    adminSplit: 0,
    userRefund: 0,
    refundSplit: 0
  })


  useEffect(() => {
    loadRevenue();
  }, [])

  const loadRevenue = async () => {
    setLoading(true);
    let res = await getRevenue();
    //console.log(res);
    if (res.success) {
      adminDispatch({ type: "REVENUE_UPDATED", payload: res.success_res });
      setLoading(false);
    } else {
      snackbar.showMessage(res.message.toUpperCase());
    }
  }


  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors({});
    setRevenue({ ...revenue, [name]: value });
  }

  const submitForm = async (e) => {
    e.preventDefault();
    //console.log(revenue);
    try {
      if (handleFormValidation()) {
        //  alert("form is valid")
        let token = localStorage.getItem('token');

        const axiosRes = await axios({
          method: "POST",
          headers: { Authorization: `Bearer ${token}` },
          url: `${BASE_URL}/admin/revenue`,
          data: revenue
        });
        //console.log(axiosRes.data);
        if (axiosRes.data.success) {
          snackbar.showMessage(axiosRes.data.message.toUpperCase());
          setRevenue({}); //setting the venue empty
          loadRevenue();
          setModalShow(false);

        }
      }
    } catch (err) {
      //console.log("submitForm [ERROR]", err);
      if (err.response.data.message) {
        snackbar.showMessage(err.response.data.message.toUpperCase());
      } else {
        snackbar.showMessage(err.message.toUpperCase());
      }


    }


  }

  const handleFormValidation = () => {
    let formIsValid = true;

    console.log(revenue);
    let newErrors = {};
    
    if (!revenue["requestVenueSplit"] || revenue['requestVenueSplit'] == "") {
      formIsValid = false;
      newErrors = { ...newErrors, "requestVenueSplit": "Venue Split is Required" };
    }

    if (!revenue["requestAdminSplit"] || revenue['requestAdminSplit'] == "") {
      formIsValid = false;
      newErrors = { ...newErrors, "requestAdminSplit": "Admin Split is Required" };
    }

    if (!revenue["upgradeRequestVenueSplit"] || revenue['upgradeRequestVenueSplit'] == "") {
      formIsValid = false;
      newErrors = { ...newErrors, "upgradeRequestVenueSplit": "Venue Split is Required" };
    }

    if (!revenue["upgradeRequestAdminSplit"] || revenue['upgradeRequestAdminSplit'] == "") {
      formIsValid = false;
      newErrors = { ...newErrors, "upgradeRequestAdminSplit": "Admin Split is Required" };
    }

    if (!revenue["upgradeRequestUsersSplit"] || revenue['upgradeRequestUsersSplit'] == "") {
      formIsValid = false;
      newErrors = { ...newErrors, "upgradeRequestUsersSplit": "Users Split is Required" };
    }

    if (!revenue["upgradeRequestSplitNoOfUsers"] || revenue['upgradeRequestSplitNoOfUsers'] == "") {
      formIsValid = false;
      newErrors = { ...newErrors, "upgradeRequestSplitNoOfUsers": "No. of Users is Required" };
    }

    setErrors({ ...errors, ...newErrors })

    return formIsValid;
  }

  const modalOpening = () => {
    setModalShow(true);
    setRevenue(revenueStats);
  }



  if (loading) { return <Loader /> }
  return (
    <>
      <div className="body-main">

        <div className="title-bar">
          <h2>Revenue Splits </h2>
        </div>

        <div className="title-bar">
          <h4>Normal Request Split</h4>
        </div>

        <div className="dashboard-boxes dashboard-components">
          <div className="boxesin Check-ins">
            <ul>
              <li>Venue Split</li>
              <li>

              </li>
            </ul>
            <div className="boxescontent">
              <h3>{revenueStats?.requestVenueSplit}%</h3>
            </div>
          </div>
          <div className="boxesin Requests">
            <ul>
              <li>Admin Split</li>
              <li>

              </li>
            </ul>
            <div className="boxescontent">
              <h3>{revenueStats?.requestAdminSplit}%</h3>
            </div>
          </div>
        </div>

        <div className="title-bar">
          <h4>Upgrade Request Split</h4>
        </div>
        <div className="dashboard-boxes dashboard-components">
          <div className="boxesin Check-ins">
            <ul>
              <li>Venue Split</li>
              <li>

              </li>
            </ul>
            <div className="boxescontent">
              <h3>{revenueStats?.upgradeRequestVenueSplit}%</h3>
            </div>
          </div>
          <div className="boxesin Requests">
            <ul>
              <li>Admin Split</li>
              <li>

              </li>
            </ul>
            <div className="boxescontent">
              <h3>{revenueStats?.upgradeRequestAdminSplit}%</h3>
            </div>
          </div>
          <div className="boxesin revenue">
            <ul>
              <li>Users Split</li>
              <li>

              </li>
            </ul>
            <div className="boxescontent">
              <h3>{revenueStats?.upgradeRequestUsersSplit}%</h3>
            </div>
          </div>
          <div className="boxesin revenue">
            <ul>
              <li>No. of Users</li>
              <li>

              </li>
            </ul>
            <div className="boxescontent">
              <h3>{revenueStats?.upgradeRequestSplitNoOfUsers}</h3>
            </div>
          </div>
        </div>

        <button className='revenue_button btn btnwhite' onClick={modalOpening}>Update Splits</button>

      </div>

      <Modal
        show={modalShow}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header onClick={(() => setModalShow(false))} closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Splits
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => submitForm(e)}>
            <h6 className='my-3'>Normal Request Split</h6>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Venue Split <span style={{ color: "red" }}>{errors["requestVenueSplit"]}</span></Form.Label>
              <Form.Control type="number" placeholder="Enter Venue split" name="requestVenueSplit" value={revenue.requestVenueSplit || ""} onChange={handleChange} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Admin Split <span style={{ color: "red" }}>{errors["requestAdminSplit"]}</span></Form.Label>
              <Form.Control type="number" placeholder="Enter Admin Split" name="requestAdminSplit" value={revenue.requestAdminSplit || ""} onChange={handleChange} />
            </Form.Group>

            <h6 className='my-3'>Upgrade Request Split</h6>

            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Venue Split <span style={{ color: "red" }}>{errors["upgradeRequestVenueSplit"]}</span></Form.Label>
              <Form.Control type="number" placeholder="Enter Venue split" name="upgradeRequestVenueSplit" value={revenue.upgradeRequestVenueSplit || ""} onChange={handleChange} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Admin Split <span style={{ color: "red" }}>{errors["upgradeRequestAdminSplit"]}</span></Form.Label>
              <Form.Control type="number" placeholder="Enter Admin Split" name="upgradeRequestAdminSplit" value={revenue.upgradeRequestAdminSplit || ""} onChange={handleChange} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicName" >
              <Form.Label>Users Split <span style={{ color: "red" }}>{errors["upgradeRequestUsersSplit"]}</span></Form.Label>
              <Form.Control type="number" placeholder="Enter User Split" name="upgradeRequestUsersSplit" value={revenue.upgradeRequestUsersSplit || ""} onChange={handleChange} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicName" >
              <Form.Label>No. of Users <span style={{ color: "red" }}>{errors["upgradeRequestSplitNoOfUsers"]}</span></Form.Label>
              <Form.Control type="number" placeholder="Enter No of Users" name="upgradeRequestSplitNoOfUsers" value={revenue.upgradeRequestSplitNoOfUsers || ""} onChange={handleChange} />
            </Form.Group>  

            <button className="form_button" variant="primary" type="submit" centered>
              Submit
            </button>
          </Form>

        </Modal.Body>
      </Modal>
    </>
  )
}

export default RevenueSplits;