import React, { useState, useEffect } from "react";
import { Table, Form, InputGroup } from "react-bootstrap";
import axios from "axios";
import Loader from "../components/Loader";
import { BsArrowUpRight, BsArrowDownRight } from "react-icons/bs";
import { BASE_URL } from "../config";
import { formatDate } from "../components/helper/commonHelper";
import { CgSearch } from "react-icons/cg";

const TransactionHistory = () => {
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [venueSortBy, setVenueSortBy] = useState(""); // Sorting state for venue transactions
  const [transactionTypeSort, setTransactionTypeSort] = useState(""); // State for sorting transaction type
  const [totalVenuePage, setTotalVenuePage] = useState(0);
  const [currentVenuePage, setCurrentVenuePage] = useState(1);
  const [venueTransactions, setVenueTransactions] = useState([]);

  useEffect(() => {
    getVenueTransaction();
  }, [currentVenuePage, venueSortBy, searchQuery, transactionTypeSort]);

  const getVenueTransaction = async () => {
    try {
      let token = localStorage.getItem("token");
      const axiosRes = await axios({
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
        url: `${BASE_URL}/admin/venues/transactions`,
        params: {
          q: searchQuery,
          page: currentVenuePage,
          limit: 15,
          sortBy: venueSortBy,
          transactionTypeSort: transactionTypeSort,
        },
      });
      if (axiosRes.data.success) {
        setVenueTransactions(axiosRes.data.success_res.transactions);
        setTotalVenuePage(axiosRes.data.success_res.totalPages);
      }
    } catch (err) {
      console.error("getVenueTransaction [ERROR] =>", err);
    }
  };

  const handleSortChange = (event) => {
    const newSortBy = event.target.value;
    if (newSortBy === "credit" || newSortBy === "debit") {
      setTransactionTypeSort(newSortBy); // Set transaction type sort
    } else {
      setTransactionTypeSort(""); // Reset transaction type sort
      setVenueSortBy(newSortBy); // Set other sorting options
    }
    setCurrentVenuePage(1); // Reset current page when changing the sorting option
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className="verification_div">
        <h1 className="verification_text">Venue Transaction History</h1>
      </div>
      <div className="main_tabs_section">
        <div className="search_sort_div posabs">
          <InputGroup className="mb-3 posrel">
            <div className="search_icon">
              <CgSearch color="var(--primary)" size={15} />
            </div>
            <Form.Control
              placeholder="Search"
              aria-label="Search"
              aria-describedby="basic-addon1"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </InputGroup>
          <div className="sortby_select">
            <Form.Select
              aria-label="Default select example"
              onChange={handleSortChange}
              value={transactionTypeSort}
            >
              <option value="">Sort By Type</option>
              <option value="credit">Credit</option>
              <option value="debit">Debit</option>
            </Form.Select>
          </div>
          <div className="sortby_select">
            <Form.Select
              aria-label="Default select example"
              onChange={handleSortChange}
              value={venueSortBy}
            >
              <option value="">Sort By</option>
              <option value="dateDsc">Date (Newest First)</option>
              <option value="dateAsc">Date (Oldest First)</option>
              <option value="high">Amount (High to Low)</option>
              <option value="low">Amount (Low to High)</option>
            </Form.Select>
          </div>
        </div>
        <div className="tabs_div">
          <div className="user_panel ">
            <Table responsive>
              <thead>
                <tr>
                  <th>Venue</th>
                  <th>Venue Name</th>
                  <th>Body</th>
                  <th>Type</th>
                  <th>Address</th>
                  <th>Date and Time</th>
                  <th>Debit/Credit</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {venueTransactions.map((transaction, index) => (
                  <tr key={index}>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="table_image me-4">
                          <img
                            src={transaction?.venue?.venuePhotos[0]?.path}
                            alt="venue"
                          />
                        </div>
                        {}
                      </div>
                    </td>
                    <td>{transaction?.venue?.title}</td>
                    <td>{transaction?.body}</td>
                    <td>{transaction?.type}</td>
                    <td>{transaction?.address}</td>
                    <td>{formatDate(transaction?.timeStamp)}</td>
                    <td>
                      {transaction.isDebit ? (
                        <span>
                          Debited <BsArrowUpRight color="red" />
                        </span>
                      ) : (
                        <span>
                          Credited <BsArrowDownRight color="green" />
                        </span>
                      )}
                    </td>
                    <td>$ {transaction?.transferredAmount}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div className="pagination-div">
            <button
              className="btn btn-light"
              onClick={() => setCurrentVenuePage((prev) => prev - 1)}
              disabled={currentVenuePage === 1}
            >
              Previous
            </button>
            <span>
              {" "}
              Page {currentVenuePage} of {totalVenuePage}{" "}
            </span>
            <button
              className="btn btn-light"
              onClick={() => setCurrentVenuePage((prev) => prev + 1)}
              disabled={currentVenuePage === totalVenuePage}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransactionHistory;
