import React, { useState, useEffect, useContext } from 'react';
import { Mcontext } from '../context/Mcontext';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import Loader from '../components/Loader';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { useSnackbar } from 'material-ui-snackbar-provider'
import { BASE_URL } from '../config';
import { BsPencil, BsPlusCircleFill } from "react-icons/bs";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { AiOutlineDrag } from 'react-icons/ai';


let currentImage = "";

const BannerImage = () => {
    const snackbar = useSnackbar();
   
    const { authState,adminState, adminDispatch,getSummary } = useContext(Mcontext);
    const { user } = authState;
    const { adminError, totalUsers, totalVenues } = adminState;

    const [modalShow, setModalShow] = useState(false);
    const [createModalShow, setCreateModalShow] = useState(false);
    const [editModalShow, setEditModalShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [bannerImages, setBannerImages] = useState([]);
    const [bannerFile,setbannerFile]=useState();
    const [bannerData, setBannerData] = useState({
        title: "",
        venueid: ""
    })

    const handleDrop = (droppedItem) => {
        // Ignore drop outside droppable container
        if (!droppedItem.destination) return;
        var updatedList = [...bannerImages];
        // Remove dragged item
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        // Add dropped item
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        // Update State
        setBannerImages(updatedList);

        const newOrderIds = updatedList.map((item) => item._id);

        updateOrderInDatabase(newOrderIds)
      };

      const updateOrderInDatabase = async (newOrderIds) => {
        try {

          let token = localStorage.getItem("token");
          
          const axiosRes = await axios({
            method: "PATCH", 
            headers: { Authorization: `Bearer ${token}` },
            url: `${BASE_URL}/admin/updateBannerImageOrder`, 
            data: { newOrderIds }, 
          });
          
          
          console.log("Updated order in the database", axiosRes?.data);
        } catch (err) {
          console.error("Failed to update order in the database", err);
        }
      };
      

    useEffect(() => {
        loadSummary();
        loadImages();
    }, [])


    const loadSummary = async () => {
        setLoading(true);
        const summaryData = await getSummary();
        if (summaryData.success) {
          adminDispatch({ type: "VENUES_LOADED", payload: summaryData.success_res.venues });
          adminDispatch({ type: "USERS_LOADED", payload: summaryData.success_res.users })
          setLoading(false);
        }else{
          setLoading(false);
        }
      }

    const loadImages = async () => {
        try {
            setLoading(true);
            let token = localStorage.getItem("token");

            const axiosRes = await axios({
                method: "GET",
                headers: { Authorization: `Bearer ${token}` },
                url: `${BASE_URL}/venues/banner`,
            });
            setLoading(false);
            //console.log("loadImages [SUCCESS]", axiosRes.data);
            if (axiosRes.data.success) {
                setBannerImages(axiosRes.data.success_res.sliderImages);
            }
        } catch (err) {
            setLoading(false);
            //console.log("loadImages [ERROR]", err)
        }
    }

    const addBannerImage = async (value) => {
        try {
            let token = localStorage.getItem("token");

            const axiosRes = await axios({
                method: "POST",
                headers: { Authorization: `Bearer ${token}` },
                url: `${BASE_URL}/admin/banner`,
                data: value
            });
            //console.log("toogleVenueRequest", axiosRes?.data);
            snackbar.showMessage(axiosRes.data.message.toUpperCase())
            loadImages();
            
        } catch (err) {
            //console.log("addBannerImage [ERROR]", err);
            snackbar.showMessage("IMAGE UPLOAD FAILED")
           
        }finally{
            
        }
    }

   
    const deleteBannerImage = async(imageid) => {
        try {
            if(window.confirm("Are you sure want to Delete This Banner Image")===true){
                // //console.log("banner image to be deleted",imageid);
                let token = localStorage.getItem("token");
    
                const axiosRes = await axios({
                    method: "DELETE",
                    headers: { Authorization: `Bearer ${token}` },
                    url: `${BASE_URL}/admin/banner/${imageid}`,
                    
                });
                //console.log("deleteBannerImage", axiosRes?.data);
                snackbar.showMessage(axiosRes.data.message.toUpperCase())
                loadImages();
            }
            
        } catch (err) {
            snackbar.showMessage("IMAGE DELETION FAILED")
            //console.log("deleteBannerImage [ERROR]", err);
        }
    }

    const openImageModal = (imgSrc) => {
       
        currentImage = imgSrc;
        setModalShow(true)
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBannerData({ ...bannerData, [name]: value });

    }

    const handleImageUpload = (e) => {
        ////console.log(e.target.files[0])
        setbannerFile(e.target.files[0]);
    }   

    const submitForm = (e) => {
        e.preventDefault();
        setCreateModalShow(false);
        const formData = new FormData();
        formData.append("banner", bannerFile);
        formData.append("title", bannerData.title);
        formData.append("venueid", bannerData.venueid);
        addBannerImage(formData);

    }



    if (loading) { return <Loader /> }
    return (
        <>
            <div className="venue">
                <h1 className="maintitle">Banner Image</h1>
                <div>
                    <button className="btnblack_new" onClick={() => setCreateModalShow(true)}> <BsPlusCircleFill size='24' />&nbsp; Add Banner Image</button>
                </div>
            </div>


            <div className='user_panel'>
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Banner Image</th>
                            <th>Title</th>
                            <th>Venue</th>
                            <th>Options</th>

                            <th>   </th>
                        </tr>
                    </thead>

                    <DragDropContext onDragEnd={handleDrop}>
        <Droppable droppableId="list-container">
          {(provided) => (
            <tbody
              className="list-container"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
                        {bannerImages && bannerImages.map((image, index) => (
                            <>

<Draggable key={image._id} draggableId={image._id} index={index}>
                  {(provided,snapshot) => (
                    <tr
                      className="item-container"
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                    >
                                    <td>
                                        <div className='banner_img'>
                                            <div className='dragicon'>
                                                <AiOutlineDrag/>
                                            </div>
                                            <div >
                                                <Link  onClick={() => openImageModal(image.imageUrl)}>
                                                <img  className='crd_img' src={image.imageUrl} alt='venue doc' />

                                                </Link>
                                            </div>
                                            {/* <a onClick={() => openImageModal(image.imageUrl)}>View Image</a> */}


                                            {/* <p>{`${BASE_URL}/`+venue.docVerify}</p> */}

                                        </div>
                                    </td>
                                    <td>{image.title}</td>
                                    <td>{image.venueId?.title}</td>


                                    <td><div className="edit banner_img">
                                        {/* <button onClick={() => setEditModalShow(true)} className="approve">Edit</button> */}
                                        <button onClick={() => deleteBannerImage(image._id)} className="reject">Delete</button>
                                    </div></td>
                                </tr>
                                )}
                                </Draggable>
                            </>
                        ))

                        }
                        {provided.placeholder}
            </tbody>
          )}
        </Droppable>
      </DragDropContext>


                </Table>
            </div>

            {/* view image modal */}
            <Modal
                show={modalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header onClick={(() => setModalShow(false))} closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {/* Banner Image */}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <p>{props.img}</p> */}
                    <div className='modal_image'>
                        <img src={currentImage} /> </div>
                </Modal.Body>
            </Modal>

            {/* add image modal */}
            <Modal
                show={createModalShow}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header onClick={(() => setCreateModalShow(false))} closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add Banner Image
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <p>{props.img}</p> */}
                    <Form onSubmit={(e) => submitForm(e)} encType="multipart/form-data">
                        <Form.Group className="mb-3" controlId="formBasicName">
                            <Form.Label>Banner Image</Form.Label>
                            <Form.Control type="file" placeholder="select banner image" name="banner" accept="image/*" onChange={handleImageUpload} />
                        </Form.Group>



                        <Form.Group className="mb-3" controlId="formBasicName">
                            <Form.Label>Title </Form.Label>
                            <Form.Control type="text" placeholder="Enter Title" name="title" onChange={handleChange} />
                        </Form.Group>


                        <Form.Group className="mb-3" controlId="formBasicText">
                    <Form.Label>Venue</Form.Label>
                    <Form.Select aria-label="Default select example" name="venueid"  onChange={handleChange}>
                        <option muted >select</option>
                        {
                            totalVenues && totalVenues.map((venue,i)=>(
                                <option value={venue._id} >{venue.title}</option>
                            ))
                        }
                    </Form.Select>
                </Form.Group> 

                        <button className="form_button" variant="primary" type="submit" centered>
                            Submit
                        </button>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* edit image modal */}
            <Modal
                show={editModalShow}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header onClick={(() => setEditModalShow(false))} closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Edit Banner Image
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <p>{props.img}</p> */}
                    <div className='modal_image'>
                        <img src={currentImage} /> </div>
                </Modal.Body>
            </Modal>

        </>
    )
}


export default BannerImage;