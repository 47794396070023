import React, { useEffect, useState, useContext } from "react";
import { Table, Form, InputGroup } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { BsArrowUpRight, BsArrowDownRight } from "react-icons/bs";
import { CgSearch } from "react-icons/cg";
import { Mcontext } from "../../context/Mcontext";
import { formatDate } from "../helper/commonHelper";
import Loader from "../Loader";

const Transactions = () => {
  let { userid, venueid } = useParams();
  const { adminState, geUserTransactions, getSingleVenueTransactions } =
    useContext(Mcontext);
  const { adminError, totalUsers } = adminState;

  const [loading, setLoading] = useState(false);
  const [boxType, setBoxType] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [transactions, setTransactions] = useState([]);
  const [sortBy, setSortBy] = useState(""); // State for regular sorting
  const [transactionTypeSort, setTransactionTypeSort] = useState(""); // State for transaction type sorting
  // const [userSort, setUserSort] = useState(""); // State for user sorting
  const [q, setQ] = useState(""); // State for search query

  useEffect(() => {
    if (userid) {
      setBoxType("user");
      loadTransactions("user");
    }
    if (venueid) {
      setBoxType("venue");
      loadTransactions("venue");
    }
  }, [currentPage, sortBy, transactionTypeSort, q]);

  const loadTransactions = async (type) => {
    if (type === "user") {
      const transactionRes = await geUserTransactions(
        userid,
        currentPage,
        sortBy,
        transactionTypeSort,
        q
      );
      if (transactionRes?.success) {
        setTransactions(transactionRes?.success_res?.transactions);
        setTotalPage(transactionRes?.success_res?.totalPages);
      }
    }
    if (type === "venue") {
      const transactionRes = await getSingleVenueTransactions(
        venueid,
        currentPage,
        sortBy,
        transactionTypeSort,
        q
      );
      if (transactionRes?.success) {
        setTransactions(transactionRes?.success_res?.transactions);
        setTotalPage(transactionRes?.success_res?.totalPages);
      }
    }
  };

  const handleSortChange = (event) => {
    const newSortBy = event.target.value;
    setSortBy(newSortBy);
  };

  const handleTransactionTypeSortChange = (event) => {
    const newTransactionTypeSort = event.target.value;
    setTransactionTypeSort(newTransactionTypeSort);
  };

  // const handleUserSortChange = (event) => {
  //   const newUserSort = event.target.value;
  //   setUserSort(newUserSort);
  // };

  const handleSearch = (e) => setQ(e.target.value);

  const chageCurrentPage = (pageType) => {
    if (pageType === "prev") {
      if (currentPage === 1) {
        alert("No Page Exist Before 1");
      } else {
        setCurrentPage(currentPage - 1);
      }
    }

    if (pageType === "next") {
      if (currentPage === totalPage) {
        alert("No Page Exist After ");
      } else {
        setCurrentPage(currentPage + 1);
      }
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className="mt-5 main_div_border">
        <h5 className="main_heading_box">Transactions</h5>
        <div className="search_sort_div">
          <div className="search_bar">
            <InputGroup className="mb-3 posrel">
              <div className="search_icon">
                <CgSearch color="var(--primary)" size={20} />
              </div>
              <Form.Control
                placeholder="Search by Body"
                aria-label="Search"
                aria-describedby="basic-addon1"
                onChange={handleSearch}
                value={q}
              />
            </InputGroup>
          </div>
          <div className="sortby_select">
            <Form.Select
              aria-label="Transaction Type Sort"
              onChange={handleTransactionTypeSortChange}
              value={transactionTypeSort}
            >
              <option value="">Transaction Type</option>
              <option value="isDebitTrue">Debit</option>
              <option value="isDebitFalse">Credit</option>
            </Form.Select>
          </div>
          <div className="sortby_select">
            <Form.Select
              aria-label="Default Sort"
              onChange={handleSortChange}
              value={sortBy}
            >
              <option value="">Sort By</option>
              {boxType === "user" && (
                <>
                  <option value="dateDsc">Date (Newest First)</option>
                  <option value="dateAsc">Date (Oldest First)</option>
                  <option value="high">Sent Amount (High to Low)</option>
                  <option value="low">Sent Amount (Low to High)</option>
                </>
              )}
              {boxType === "venue" && (
                <>
                  <option value="dateDsc">Date (Newest First)</option>
                  <option value="dateAsc">Date (Oldest First)</option>
                  <option value="high">Sent Amount (High to Low)</option>
                  <option value="low">Sent Amount (Low to High)</option>
                  <option value="userNameAsc">User Name (A-Z)</option>
                  <option value="userNameDsc">User Name (Z-A)</option>
                </>
              )}
            </Form.Select>
          </div>
        </div>
        <div className="user_panel ">
          <Table responsive>
            <thead>
              <tr>
                <th>Date</th>
                <th>Body</th>
                <th>Amount</th>
                <th>Transaction Type</th>
                <th>Debit/Credit</th>
                {boxType === "venue" && <th>User Name</th>}
              </tr>
            </thead>
            <tbody>
              {transactions?.length === 0 && (
                <tr>
                  <td colSpan={boxType === "user" ? 5 : 6}>No Transactions!</td>
                </tr>
              )}
              {transactions &&
                transactions.map((singleTxn, index) => (
                  <tr key={index}>
                    <td>{formatDate(singleTxn?.createdAt)}</td>
                    <td>{singleTxn.body}</td>
                    <td>${singleTxn.transferredAmount}</td>
                    <td>{singleTxn.transactionType}</td>
                    <td>
                      {singleTxn.isDebit ? (
                        <span>
                          Debited <BsArrowUpRight color="red" />
                        </span>
                      ) : (
                        <span>
                          Credited <BsArrowDownRight color="green" />
                        </span>
                      )}
                    </td>
                    {boxType === "venue" && (
                      <td>{singleTxn.rawData?.userName}</td>
                    )}
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
        <div className="pagination_box">
          <div className="pagination-div">
            <button
              className="btn btn-light"
              onClick={() => chageCurrentPage("prev")}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span>
              {" "}
              Page {currentPage} of {totalPage}{" "}
            </span>
            <button
              className="btn btn-light"
              onClick={() => chageCurrentPage("next")}
              disabled={currentPage === totalPage}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Transactions;
