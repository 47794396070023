import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Link } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import React, { useState, useContext } from 'react';
import { Mcontext } from '../../context/Mcontext';
import { useNavigate } from 'react-router-dom';
import 'firebase/compat/auth';
import axios from "axios";
import { BASE_URL } from "../../config";
import Loader from '../../components/Loader';

import firebase from 'firebase/compat/app';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { useSnackbar } from 'material-ui-snackbar-provider'

import { auth } from '../../helper/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import SmallLoader from "../../components/SmallLoader";

const Login = () => {
  const snackbar = useSnackbar();

  const { authState, authDispatch, login, } = useContext(Mcontext);
  const { authError } = authState;
  const [user] = useAuthState(auth);

  const [otpSent, setSentOtp] = useState(null)
  const [otp, setOtp] = useState('')
  const [loginMode, setLoginMode] = useState("email");
  const [phoneNumber, setPhoneNumber] = useState('');
  const [confirmationResult, setConfirmationResult] = useState(null);

  const [loading, setLoading] = useState(false)

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [loginInfo, setLoginInfo] = useState({
    email: "",
    password: ""
  })

  // Eye Enable or disable password

  const handleTogglePassword = () => {
    setShowPassword(prevState => !prevState);
  };


  const loginWithMobile = async (userinfo) => {
    try {
      const axiosRes = await axios({
        method: "POST",
        url: `${BASE_URL}/admin/admin-moblogin`,
        data: userinfo,
      });
      return axiosRes?.data;

    } catch (err) {
      //console.log("login [ERROR]", err);
      return err;
    }
  }

  const handleLogin = async (e) => {
    e.preventDefault();
    if (checkInput()) {
      setLoading(true);
      const res = await login(loginInfo);
      if (res.success) {
        const number = res?.success_res?.user?.phone
        if (!number) {
          toast("No Phone Number associated with this account")
        }
        setPhoneNumber(number)
        const appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
          size: 'invisible'
        });
        try {

          const confirmation = await firebase.auth().signInWithPhoneNumber(number, appVerifier);
          setConfirmationResult(confirmation);
          setSentOtp(true);
          setLoading(false);

        } catch (error) {
          toast(error.message);
          setLoading(false);
        }
      } else {
        setLoading(false);
        snackbar.showMessage(res.message);
        if (res.response) {
          snackbar.showMessage(res.response.data.message);
        }
      }
    }
  }

  const handleChange = (e) => {
    setLoginInfo({ ...loginInfo, [e.target.name]: e.target.value });
  }

  const checkInput = () => {
    if (loginInfo.email === "") {
      snackbar.showMessage("Please Input Your Email");
      return false;
    }
    if (loginInfo.password === "") {
      snackbar.showMessage("Please Input Your Password");
      return false;
    }
    return true;
  }


  const LoginWithMob = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const verified = await confirmationResult.confirm(otp);
      // const inputTag = document.getElementById("otp")
      // inputTag.value = ''
      const payload = {
        mob: phoneNumber,
        hash: verified.user._delegate.accessToken,
        otp: otp
      }
      const res = await loginWithMobile(payload);
      if (res.success) {
        localStorage.setItem('token', res.success_res.token);
        authDispatch({ type: "LOGIN", payload: res.success_res.user })
        setLoading(false)
        navigate('/');
      } else {
        setLoading(false);
        toast(res.message);
        if (res.response) {
          toast(res.response.data.message);
          setTimeout(() => {
            window.location.reload('false')
          }, 3000)
        }
      }
    } catch (error) {
      console.log(error, "error")
      setLoading(false);
      const msg = error.code.split('/')
      toast(msg[1])
      setTimeout(() => {
        window.location.reload('false')
      }, 3000)
      console.log(error, "eroorororor")
    }
  }

  return (

    <>
      <div id="recaptcha-container"></div>
      <ToastContainer />
      <div className="title-bar text-center">
        <h2 className="text-dark text-start py-4">Log In</h2>
      </div>

      <div className="Form">

        {
          !otpSent ?
            <>

              <Form onSubmit={handleLogin}>
                <Form.Group className="mb-3" controlId="formBasicEmaillogin">


                  <Form.Label>Your Email</Form.Label>
                  <Form.Control
                    name="email"
                    type="email"
                    placeholder="e.g. smith@gmail.com"
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="password">
                  <Form.Label>Your password</Form.Label>

                  <InputGroup className="mb-3 formeye">
                    <Form.Control
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Enter your password"
                      name="password"
                      onChange={handleChange}
                    />
                    <InputGroup.Text
                      onClick={handleTogglePassword}
                      style={{ cursor: 'pointer' }}
                    >
                      {showPassword ? <AiOutlineEye /> : < AiOutlineEyeInvisible />}
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>

                {
                  loading ? <>
                    <SmallLoader />
                  </> : <>
                    <Button className="btn btnwhite" type="submit" >
                      Log In
                    </Button>
                  </>
                }
              </Form>
            </>
            :

            <Form onSubmit={LoginWithMob}>
              <Form.Group className="mb-3" controlId="formBasicEmaillogin">


                <Form.Label>OTP</Form.Label>
                <Form.Control
                  name="otp"
                  type="number"
                  placeholder="Enter Otp"
                  onChange={(e) => setOtp(e.target.value)}
                />
              </Form.Group>

              {
                loading ? <>
                  <SmallLoader />
                </> : <>
                  <Button className="btn btnwhite" type="submit" >
                    Login
                  </Button>
                </>
              }

            </Form>



        }




      </div>
    </>
  );
};

export default Login;
