import React from 'react';
import { Blocks } from  'react-loader-spinner'


const Loader = () => {
    return (
        <>
        <div className='loader'>
        <Blocks
                visible={true}
                height="80"
                width="80"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
            />
            <h4>Loading...</h4>
        </div>

        </>
    )
}

export default Loader