import React, { useState, useEffect } from "react";
import { Table, Form, InputGroup } from "react-bootstrap";
import { BsArrowUpRight, BsArrowDownRight } from "react-icons/bs";
import { CgSearch } from "react-icons/cg";
import { BASE_URL } from "../config";
import axios from "axios";
import Loader from "../components/Loader";
import { formatDate } from "../components/helper/commonHelper";

const TransactionHistory = () => {
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [userSortBy, setUserSortBy] = useState(""); // Sorting state for regular sorting
  const [transactionTypeSort, setTransactionTypeSort] = useState(""); // Sorting state for transaction type
  const [totalUserPage, setTotalUserPage] = useState(0);
  const [currentUserPage, setCurrentUserPage] = useState(1);
  const [userTransactions, setUserTransactions] = useState([]);

  useEffect(() => {
    getUserTransaction();
  }, [currentUserPage, userSortBy, transactionTypeSort, searchQuery]);

  const getUserTransaction = async () => {
    try {
      let token = localStorage.getItem("token");

      const axiosRes = await axios({
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
        url: `${BASE_URL}/admin/users/transactions`,
        params: {
          q: searchQuery,
          page: currentUserPage,
          limit: 10,
          sortBy: userSortBy,
          transactionTypeSort: transactionTypeSort,
        },
      });
      if (axiosRes.data.success) {
        setUserTransactions(axiosRes.data.success_res);
        setTotalUserPage(axiosRes.data.success_res.pages);
        setCurrentUserPage(axiosRes.data.success_res.page);
      }
    } catch (err) {
      console.error("getUserTransaction [ERROR] =>", err);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSortChange = (event) => {
    setUserSortBy(event.target.value);
    setCurrentUserPage(1); // Reset current page when sorting changes
  };

  const handleTransactionTypeSortChange = (event) => {
    setTransactionTypeSort(event.target.value);
    setCurrentUserPage(1); // Reset current page when sorting changes
  };

  const chageCurrentPage = (pageType) => {
    if (pageType === "prev") {
      if (currentUserPage === 1) {
        alert("No Page Exist Before 1");
      } else {
        setCurrentUserPage((prev) => parseInt(prev) - 1);
      }
    }

    if (pageType === "next") {
      if (currentUserPage === totalUserPage) {
        alert("No Page Exist After ");
      } else {
        setCurrentUserPage((prev) => parseInt(prev) + 1);
      }
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className="verification_div">
        <h1 className="verification_text">User Transaction History</h1>
      </div>
      <div className="main_tabs_section">
        <div className="search_sort_div posabs">
          <InputGroup className="mb-3 posrel">
            <div className="search_icon">
              <CgSearch color="var(--primary)" size={15} />
            </div>
            <Form.Control
              placeholder="Search"
              aria-label="Search"
              aria-describedby="basic-addon1"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </InputGroup>
          <div className="sortby_select">
            <Form.Select
              aria-label="Transaction Type Sort"
              onChange={handleTransactionTypeSortChange}
              value={transactionTypeSort}
            >
              <option value="">Transaction Type</option>
              <option value="isDebitTrue">Debit</option>
              <option value="isDebitFalse">Credit</option>
            </Form.Select>
          </div>
          <div className="sortby_select">
            <Form.Select
              aria-label="Default Sort"
              onChange={handleSortChange}
              value={userSortBy}
            >
              <option value="">Sort By</option>
              <option value="dateDsc">Date (Newest First)</option>
              <option value="dateAsc">Date (Oldest First)</option>
              <option value="high">Amount (High to Low)</option>
              <option value="low">Amount (Low to High)</option>
            </Form.Select>
          </div>
        </div>
        <div className="tabs_div">
          <div className="user_panel ">
            <Table responsive>
              <thead>
                <tr>
                  <th>User</th>
                  <th>User Name</th>
                  <th>Body</th>
                  <th>Date and Time</th>
                  <th>Debit/Credit</th>
                  <th>Amount</th>
                </tr>
              </thead>

              <tbody>
                {userTransactions &&
                  userTransactions?.docs?.map((transaction, index) => (
                    <tr key={index}>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="table_image me-4">
                            <img src={transaction.userId.photo} alt="User" />
                          </div>
                        </div>
                      </td>
                      <td>{transaction?.userId?.name}</td>
                      <td>{transaction?.body}</td>
                      <td>{formatDate(transaction?.timeStamp)}</td>
                      <td>
                        {transaction.isDebit ? (
                          <span>
                            Debited <BsArrowUpRight color="red" />
                          </span>
                        ) : (
                          <span>
                            Credited <BsArrowDownRight color="green" />
                          </span>
                        )}
                      </td>
                      <td>${transaction?.transferredAmount}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
          <div className="pagination-div">
            <button
              className="btn btn-light"
              onClick={() => chageCurrentPage("prev")}
              disabled={currentUserPage === 1}
            >
              Previous
            </button>
            <span>
              {" "}
              Page {currentUserPage} of {totalUserPage}{" "}
            </span>
            <button
              className="btn btn-light"
              onClick={() => chageCurrentPage("next")}
              disabled={currentUserPage === totalUserPage}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransactionHistory;
