import React, { } from "react";
import Navbar from 'react-bootstrap/Navbar';

const Header = () => {
  return (
    <>
      <header className="mainheader">
        <Navbar  expand="xl">
            <Navbar.Brand className="bg-black" href="#home">
                <img src='/images/mw_logo_2.png' height={80} alt="logo white"/>
            </Navbar.Brand>
            <Navbar.Toggle onClick={()=>document.body.classList.toggle('menu-open')} aria-controls="basic-navbar-nav" />
        </Navbar>
      </header>
    </>
  );
};

export default Header;
