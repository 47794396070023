import React, { useState, useEffect } from "react";
import { Table, Tab, Tabs, Form, InputGroup } from "react-bootstrap";
import { BsArrowUpRight, BsArrowDownLeft } from "react-icons/bs";
import { CgSearch } from "react-icons/cg";
import { BASE_URL } from "../config";
import { useSnackbar } from "material-ui-snackbar-provider";
import axios from "axios";
import Loader from "../components/Loader";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { formatDate } from "../components/helper/commonHelper";

const TransactionHistory = () => {
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [userSortBy, setUserSortBy] = useState(""); // Sorting state for user transactions
  const [venueSortBy, setVenueSortBy] = useState(""); // Sorting state for venue transactions
  const [totalUserPage, setTotalUserPage] = useState(0);
  const [totalVenuePage, setTotalVenuePage] = useState(0);
  const [currentUserPage, setCurrentUserPage] = useState(1);
  const [currentVenuePage, setCurrentVenuePage] = useState(1);
  const [userTransactions, setUserTransactions] = useState([]);
  const [venueTransactions, setVenueTransactions] = useState([]);
  const [activeTab, setActiveTab] = useState("UserTransaction"); // Initialize with the default tab

  useEffect(() => {
    getUserTransaction();
  }, [currentUserPage, userSortBy, searchQuery, activeTab]);

  useEffect(() => {
    getVenueTransaction();
  }, [currentVenuePage, venueSortBy, searchQuery, activeTab]);

  const chageCurrentPage = (pageType, userType) => {
    if (userType === "user") {
      if (pageType === "prev") {
        if (currentUserPage === 1) {
          alert("No Page Exist Before 1");
        } else {
          setCurrentUserPage(currentUserPage - 1);
          getUserTransaction();
        }
      }

      if (pageType === "next") {
        if (currentUserPage === totalUserPage) {
          alert("No Page Exist After ");
        } else {
          setCurrentUserPage(currentUserPage + 1);
          getUserTransaction();
        }
      }
    }

    if (userType === "venue") {
      if (pageType === "prev") {
        if (currentVenuePage === 1) {
          alert("No Page Exist Before 1");
        } else {
          setCurrentVenuePage(currentVenuePage - 1);
          getVenueTransaction();
        }
      }

      if (pageType === "next") {
        if (currentVenuePage === totalVenuePage) {
          alert("No Page Exist After ");
        } else {
          setCurrentVenuePage(currentVenuePage + 1);
          getVenueTransaction();
        }
      }
    }
  };

  const getUserTransaction = async () => {
    try {
      let token = localStorage.getItem("token");
      setLoading(true);
      const axiosRes = await axios({
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
        url: `${BASE_URL}/admin/users/transactions?q=${searchQuery}&page=${currentUserPage}&limit=15&sortBy=${
          activeTab === "UserTransaction" ? userSortBy : ""
        }`,
      });
      if (axiosRes.data.success) {
        setLoading(false);
        setUserTransactions(axiosRes.data.success_res.transactions);
        setTotalUserPage(axiosRes.data.success_res.totalPages);
      }
    } catch (err) {
      console.error("getUserTransaction [ERROR] =>", err);
    }
  };

  const getVenueTransaction = async () => {
    try {
      let token = localStorage.getItem("token");
      setLoading(true);
      const axiosRes = await axios({
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
        url: `${BASE_URL}/admin/venues/transactions?q=${searchQuery}&page=${currentVenuePage}&limit=15&sortBy=${
          activeTab === "VenueTransactions" ? venueSortBy : ""
        }`,
      });
      if (axiosRes.data.success) {
        setVenueTransactions(axiosRes.data.success_res.transactions);
        setTotalVenuePage(axiosRes.data.success_res.totalPages);
        setLoading(false);
      }
    } catch (err) {
      console.error("getVenueTransaction [ERROR] =>", err);
    }
  };

  const handleTabChange = (tabKey) => {
    setActiveTab(tabKey);
    // Reset sorting state when switching tabs
    setUserSortBy("");
    setVenueSortBy("");
  };

  const handleSortChange = (event) => {
    const newSortBy = event.target.value;
    if (activeTab === "UserTransaction") {
      setUserSortBy(newSortBy);
    } else if (activeTab === "VenueTransactions") {
      setVenueSortBy(newSortBy);
    }
    // You may want to reset the current page to 1 when changing the sorting option
    setCurrentUserPage(1);
    setCurrentVenuePage(1);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className="verification_div">
        <h1 className="verification_text">Transaction History</h1>
        <div className="sortby_select">
          <Form.Select
            aria-label="Default select example"
            onChange={handleSortChange}
            value={activeTab === "UserTransaction" ? userSortBy : venueSortBy}
          >
            <option value="">Sort By</option>
            <option value="dateDsc">Date (Newest First)</option>
            <option value="dateAsc">Date (Oldest First)</option>
            <option value="high">Amount (High to Low)</option>
            <option value="low">Amount (Low to High)</option>
          </Form.Select>
        </div>
      </div>
      <div className="main_tabs_section">
        <div className="tabs_div">
          <Tabs
            defaultActiveKey={activeTab}
            id="uncontrolled-tab-example"
            className="mb-4 tabs-bg"
            onSelect={handleTabChange} // Add tab change event handler
          >
            <Tab eventKey="VenueTransactions" title="Venue Transactions">
              <div className="user_panel ">
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Venue</th>
                      <th>Body</th>
                      <th>Type</th>
                      <th>Address</th>
                      <th>Date and Time</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {venueTransactions &&
                      venueTransactions.map((transaction, index) => (
                        <tr key={index}>
                          <td>
                            <div className="d-flex align-items-center">
                              <div className="table_image me-4">
                                <img src={transaction.photo} />
                              </div>
                              {}
                            </div>
                          </td>
                          <td>{transaction?.body}</td>
                          <td>{transaction?.type}</td>
                          <td>{transaction?.address}</td>
                          <td>{formatDate(transaction?.timeStamp)}</td>
                          <td>$ {transaction?.amount}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
              <div className="pagination-div">
                <button
                  className="btn btn-light"
                  onClick={() => chageCurrentPage("prev", "venue")}
                >
                  Previous
                </button>
                <span>
                  {" "}
                  Page {currentVenuePage} of {totalVenuePage}{" "}
                </span>
                <button
                  className="btn btn-light"
                  onClick={() => chageCurrentPage("next", "venue")}
                >
                  Next
                </button>
              </div>{" "}
            </Tab>
            <Tab eventKey="UserTransaction" title="User Transaction">
              <div className="user_panel ">
                <Table responsive>
                  <thead>
                    <tr>
                      <th>User</th>
                      <th>Body</th>
                      <th>Date and Time</th>
                      <th>Debit/Credit</th>
                      <th>Amount</th>
                    </tr>
                  </thead>

                  <tbody>
                    {userTransactions &&
                      userTransactions.map((transaction, index) => (
                        <tr key={index}>
                          <td>
                            <div className="d-flex align-items-center">
                              <div className="table_image me-4">
                                <img src={transaction?.photo} />
                              </div>
                              {}
                            </div>
                          </td>
                          <td> {transaction?.body}</td>
                          <td>{formatDate(transaction?.timeStamp)}</td>
                          <td>
                            Debited <BsArrowUpRight color="red" />
                          </td>
                          <td>$ {transaction?.amount}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
              <div className="pagination-div">
                <button
                  className="btn btn-light"
                  onClick={() => chageCurrentPage("prev", "user")}
                >
                  Previous
                </button>
                <span>
                  {" "}
                  Page {currentUserPage} of {totalUserPage}{" "}
                </span>
                <button
                  className="btn btn-light"
                  onClick={() => chageCurrentPage("next", "user")}
                >
                  Next
                </button>
              </div>
            </Tab>
          </Tabs>
        </div>
        <div className="search_sort_div posabs">
          {/* <div className='search_bar'>
                        <InputGroup className="mb-3 posrel">
                            <div className='search_icon'>
                                <CgSearch color='var(--primary)' size={20} />
                            </div>
                            <Form.Control
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="basic-addon1"
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </InputGroup>
                    </div> */}
          {/* <div className="sortby_select">
            <Form.Select
              aria-label="Default select example"
              onChange={handleSortChange}
              value={activeTab === "UserTransaction" ? userSortBy : venueSortBy}
            >
              <option value="">Sort By</option>
              <option value="dateDsc">Date (Newest First)</option>
              <option value="dateAsc">Date (Oldest First)</option>
              <option value="high">Amount (High to Low)</option>
              <option value="low">Amount (Low to High)</option>
            </Form.Select>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default TransactionHistory;
