import React, { useState, useEffect } from "react";
import { Table, Tab, Tabs, Form, InputGroup } from "react-bootstrap";
import { BsArrowUpRight, BsArrowDownLeft, BsArrowUpLeft } from "react-icons/bs";
import { CgSearch } from "react-icons/cg";
import { BASE_URL } from "../config";
import { useSnackbar } from "material-ui-snackbar-provider";
import axios from "axios";
import Loader from "../components/Loader";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { formatDate } from "../components/helper/commonHelper";

const AdminTransactionHistory = () => {
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [transactions, setTransactions] = useState([]);
  const [transactionTypeSort, setTransactionTypeSort] = useState(""); // Sorting state for transaction type

  useEffect(() => {
    getAdminTransaction();
  }, [currentPage, sortBy, searchQuery, transactionTypeSort]);

  const chageCurrentPage = (pageType) => {
    if (pageType === "prev") {
      if (currentPage === 1) {
        alert("No Page Exist Before 1");
      } else {
        setCurrentPage(currentPage - 1);
        getAdminTransaction();
      }
    }

    if (pageType === "next") {
      if (currentPage === totalPage) {
        alert("No Page Exist After ");
      } else {
        setCurrentPage(currentPage + 1);
        getAdminTransaction();
      }
    }
  };

  const getAdminTransaction = async () => {
    try {
      let token = localStorage.getItem("token");
      const axiosRes = await axios({
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
        url: `${BASE_URL}/admin/transactions?q=${searchQuery}&page=${currentPage}&limit=15&sortBy=${sortBy}&transactionTypeSort=${transactionTypeSort}`,
      });
      if (axiosRes.data.success) {
        setTransactions(axiosRes.data.success_res.transactions);
        setTotalPage(axiosRes.data.success_res.totalPages);
        setCurrentPage(axiosRes.data.success_res.page);
      }
    } catch (err) {
      console.error("getUserTransaction [ERROR] =>", err);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleTransactionTypeSortChange = (event) => {
    setTransactionTypeSort(event.target.value);
    setCurrentPage(1); // Reset current page when sorting changes
  };

  const handleSortChange = (event) => {
    const newSortBy = event.target.value;

    setSortBy(newSortBy);

    // Reset the current page to 1 when changing the sorting option
    setCurrentPage(1);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className="verification_div">
        <h1 className="verification_text">Admin Transaction History</h1>
      </div>
      <div className="main_tabs_section">
        <div className="tabs_div">
          <div className="search_sort_div posabs">
            <InputGroup className="mb-3 posrel">
              <div className="search_icon">
                <CgSearch color="var(--primary)" size={15} />
              </div>
              <Form.Control
                placeholder="Search"
                aria-label="Search"
                aria-describedby="basic-addon1"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </InputGroup>
            <div className="sortby_select">
              <Form.Select
                aria-label="Transaction Type Sort"
                onChange={handleTransactionTypeSortChange}
                value={transactionTypeSort}
              >
                <option value="">Transaction Type</option>
                <option value="isDebitTrue">Debit</option>
                <option value="isDebitFalse">Credit</option>
              </Form.Select>
            </div>
            <div className="sortby_select">
              <Form.Select
                aria-label="Default Sort"
                onChange={handleSortChange}
                value={sortBy}
              >
                <option value="">Sort By</option>
                <option value="UserAsc">User Name (A-Z)</option>
                <option value="UserDsc">User Name (Z-A)</option>
                <option value="VenueAsc">Venue Name (A-Z)</option>
                <option value="VenueDsc">Venue Name (Z-A)</option>
              </Form.Select>
            </div>
          </div>

          <div className="user_panel ">
            <Table responsive>
              <thead>
                <tr>
                  <th>Transaction Type</th>
                  <th>Transaction Body</th>
                  <th>Date and Time</th>
                  <th>Debit/Credit</th>
                  <th>Transfered Amount</th>
                  <th>User Name</th>
                  <th>Venue Name</th>
                </tr>
              </thead>

              <tbody>
                {transactions &&
                  transactions.map((transaction, index) => (
                    <tr key={index}>
                      <td>
                        {transaction.transactionType.split("_").join(" ")}
                      </td>
                      <td>{transaction.body}</td>
                      <td>{formatDate(transaction?.createdAt)}</td>
                      {transaction.isDebit === false ? (
                        <td>
                          Credited <BsArrowUpLeft color="green" />
                        </td>
                      ) : (
                        <td>
                          Debited <BsArrowUpRight color="red" />
                        </td>
                      )}
                      <td>$ {transaction?.transferredAmount}</td>
                      <td>{transaction?.user?.name}</td>
                      <td>{transaction?.venue?.title}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
          <div className="pagination-div">
            <button
              className="btn btn-light"
              onClick={() => chageCurrentPage("prev")}
            >
              Previous
            </button>
            <span>
              {" "}
              Page {currentPage} of {totalPage}{" "}
            </span>
            <button
              className="btn btn-light"
              onClick={() => chageCurrentPage("next")}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminTransactionHistory;
