import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { Mcontext } from "../../context/Mcontext";
import { Form, Pagination, InputGroup, Table } from "react-bootstrap";
import { CgSearch } from "react-icons/cg";
import { formatDate } from "../helper/commonHelper";
import Loader from "../Loader";

const RequestHistory = () => {
  let { userid, venueid } = useParams();
  const {
    adminState,
    getUserRequests,
    getSingleVenueRequests,
    getUserCheckins,
  } = useContext(Mcontext);
  const { adminError, totalUsers } = adminState;

  const [loading, setLoading] = useState(false);
  const [boxType, setBoxType] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [requests, setRequests] = useState([]);
  const [userSortBy, setUserSortBy] = useState("");
  const [q, setQ] = useState("");

  useEffect(() => {
    if (userid) {
      setBoxType("user");
      loadRequests("user");
    }
    if (venueid) {
      setBoxType("venue");
      loadRequests("venue");
    }
  }, [currentPage, userSortBy, q]);

  const loadRequests = async (type) => {
    if (type === "user") {
      const requestsRes = await getUserRequests(
        userid,
        currentPage,
        userSortBy,
        q
      );

      if (requestsRes?.success) {
        setRequests(requestsRes?.success_res?.requests);
        setTotalPage(requestsRes?.success_res?.totalPages);
      }
    }
    if (type === "venue") {
      const requestsRes = await getSingleVenueRequests(
        venueid,
        currentPage,
        "all",
        userSortBy,
        q
      );

      if (requestsRes) {
        setRequests(requestsRes?.requests);
        setTotalPage(requestsRes?.totalPages);
      }
    }
  };

  const handleSortChange = (event) => {
    setUserSortBy(event.target.value);
    setCurrentPage(1);
  };

  const handleSearch = (e) => setQ(e.target.value);

  const chageCurrentPage = (pageType) => {
    if (pageType === "prev") {
      if (currentPage === 1) {
        alert("No Page Exist Before 1");
      } else {
        setCurrentPage(currentPage - 1);
      }
    }

    if (pageType === "next") {
      if (currentPage === totalPage) {
        alert("No Page Exist After ");
      } else {
        setCurrentPage(currentPage + 1);
      }
    }
  };
  console.log(requests, "SETTTTTTTRRRREEEEESSSSTTTT");

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className="mt-5 main_div_border">
        <h5 className="main_heading_box">Recent Requests</h5>
        <div className="main_div_sortby_search">
          <div className="search_sort_div">
            <div className="search_bar">
              <InputGroup className="mb-3 posrel">
                <div className="search_icon">
                  <CgSearch color="var(--primary)" size={20} />
                </div>
                <Form.Control
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="basic-addon1"
                  onChange={handleSearch}
                />
              </InputGroup>
            </div>
            <div className="sortby_select">
              <Form.Select
                aria-label="Default select example"
                onChange={handleSortChange}
                value={userSortBy}
              >
                <option value="">Sort By</option>
                {boxType === "user" && (
                  <>
                    <option value="dateAsc">Date (Oldest First)</option>
                    <option value="dateDsc">Date (Newest First)</option>
                    <option value="amount">Amount</option>
                    <option value="songName">Song Name</option>
                    <option value="singerName">Singer Name</option>
                    <option value="venueName">Venue Name</option>
                  </>
                )}
                {boxType === "venue" && (
                  <>
                    <option value="dateAsc">Date (Oldest First)</option>
                    <option value="dateDsc">Date (Newest First)</option>
                    <option value="amountAsc">Amount (Low-High)</option>
                    <option value="amountDsc">Amount (High-Low)</option>
                    <option value="songName">Song Name</option>
                    <option value="singerName">Singer Name</option>
                    <option value="venueName">Venue Name</option>
                    <option value="userNameAsc">User Name (A-Z)</option>
                    <option value="userNameDsc">User Name (Z-A)</option>
                  </>
                )}
              </Form.Select>
            </div>
          </div>
        </div>

        <div className="user_panel ">
          <Table responsive>
            <thead>
              <tr>
                <th>Song Poster</th>
                <th>Song Name</th>
                <th>Singer Name</th>
                <th>Venue Name</th>
                <th>Date</th>
                <th>Amount</th>
                {boxType === "venue" && <th>User Name</th>}
              </tr>
            </thead>

            <tbody>
              {requests?.length === 0 && (
                <tr>
                  <td colSpan="7">No Songs Requests!</td>
                </tr>
              )}
              {requests &&
                requests?.map((singleRequest, index) => (
                  <tr key={index}>
                    <td>
                      {" "}
                      <img
                        src={singleRequest?.songId?.songCoverImage}
                        width={75}
                        height={75}
                      />
                    </td>
                    <td>{singleRequest?.songId?.songName}</td>
                    <td>{singleRequest?.songId?.songArtist}</td>
                    <td>${singleRequest?.venueId?.title}</td>
                    <td>{formatDate(singleRequest?.requestedAt)}</td>
                    <td>${singleRequest?.amountPaid}</td>
                    {boxType === "venue" && (
                      <td>{singleRequest?.userId?.name}</td>
                    )}
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>

        <div className="pagination_box">
          <div className="pagination-div">
            <button
              className="btn btn-light"
              onClick={() => chageCurrentPage("prev")}
            >
              Previous
            </button>
            <span>
              {" "}
              Page {currentPage} of {totalPage}{" "}
            </span>
            <button
              className="btn btn-light"
              onClick={() => chageCurrentPage("next")}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestHistory;
