
import firebase from 'firebase/compat/app';
import "firebase/compat/auth"
import 'firebase/compat/firestore'

//seraphic music ways
const firebaseConfig = {
  apiKey: "AIzaSyAnoHkuweMtSSK4rTRVbE4pgJinMPT4558",
  authDomain: "musicways-e1faf.firebaseapp.com",
  projectId: "musicways-e1faf",
  storageBucket: "musicways-e1faf.appspot.com",
  messagingSenderId: "1037642058008",
  appId: "1:1037642058008:web:cef21192cd65c28aacb66d",
  measurementId: "G-6Z9BT96W5S"
};

firebase.initializeApp(firebaseConfig);

// Export Firebase modules you'll use (optional)
const auth = firebase.auth();
const firestore = firebase.firestore();

export { auth, firestore };