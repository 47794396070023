import React, { useContext, useEffect } from "react";
import "./App.css";
import "./css/style.css";
import "./fonts/stylesheet.css";

import { Routes, Route, Navigate } from "react-router-dom";
import { Mcontext } from "./context/Mcontext";
import TermsAndConditions from "./pages/Terms";
import ProtectedRoute from "./pages/ProtectedRoute";
import Auth from "./components/Auth/index";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SnackbarProvider } from "material-ui-snackbar-provider";

import VenueTransactionHistory from "./pages/VenueTransactionHistory";
import UserTransactionHistory from "./pages/UserTransactionHistory";
import FrontendPanel from "./components/FrontendPanel/index";
import Authentication from "./components/Authentication/index";
import Dashboard from "./pages/Dashboard";
import Verification from "./pages/Verification";
import PayoutRequest from "./pages/PayoutRequest";
import User from "./pages/User";
import Venue from "./pages/Venue";

import RevenueSplits from "./pages/RevenueSplits";

import Register from "./pages/auth/Register";
import BannerImage from "./pages/BannerImage";
import VenueDetail from "./pages/admin/VenueDetail";
import UserDetails from "./pages/admin/UserDetails";
import TransactionHistory from "./pages/TransactionHistory";

import toast, { Toaster } from "react-hot-toast";
import PrivacyPolicy from "./pages/PrivacyPolicy";

import AdminLogs from "./pages/AdminLogs";
import SubAdmin from "./pages/SubAdmin";
import ChangePassword from "./pages/ChangePassword";
import AdminTransactionHistory from "./pages/AdminTransactionHistory";

function App() {
  const { authState } = useContext(Mcontext);
  const { isLoggedIn, user } = authState;

  useEffect(() => {
    // adminDispatch({ type: "LOGIN", payload: "admin dispatch success" });
  }, []);

  return (
    <>
      <ToastContainer />
      <Toaster />
      <SnackbarProvider
        SnackbarProps={{
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "top", horizontal: "right" },
        }}
      >
        <Routes>
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />

          <Route path="/:languageCode/terms" element={<TermsAndConditions />} />
          <Route path="/:languageCode/privacy" element={<PrivacyPolicy />} />

          {!isLoggedIn ? (
            <>
              <Route path="/" element={<Authentication />}>
                <Route path="/" element={<Auth />} />
                <Route path="/login" element={<Auth />} />
                <Route path="/register" element={<Register />} />
              </Route>
            </>
          ) : (
            <>
              <Route path="/" element={<FrontendPanel />}>
                <Route
                  path="/"
                  element={<ProtectedRoute component={<Dashboard />} />}
                />
                <Route
                  path="/venuedetail/:venueid"
                  element={<ProtectedRoute component={<VenueDetail />} />}
                />
                <Route
                  path="/requests"
                  element={<ProtectedRoute component={<Verification />} />}
                />
                <Route
                  path="/users"
                  element={<ProtectedRoute component={<User />} />}
                />
                <Route
                  path="/venues"
                  element={<ProtectedRoute component={<Venue />} />}
                />
                <Route
                  path="/change-password"
                  element={<ProtectedRoute component={<ChangePassword />} />}
                />
                <Route
                  path="/transactionhistory"
                  element={
                    <ProtectedRoute component={<TransactionHistory />} />
                  }
                />
                <Route
                  path="/admintransactionhistory"
                  element={
                    <ProtectedRoute component={<AdminTransactionHistory />} />
                  }
                />
                <Route
                  path="/venuetransactionhistory"
                  element={
                    <ProtectedRoute component={<VenueTransactionHistory />} />
                  }
                />
                <Route
                  path="/usertransactionhistory"
                  element={
                    <ProtectedRoute component={<UserTransactionHistory />} />
                  }
                />

                <Route
                  path="/userdetails/:userid"
                  element={<ProtectedRoute component={<UserDetails />} />}
                />
                <Route
                  path="/revenue"
                  element={<ProtectedRoute component={<RevenueSplits />} />}
                />
                <Route path="/sub-admin" element={<SubAdmin />} />
                <Route path="/admin-logs" element={<AdminLogs />} />
                <Route
                  path="/banner-image"
                  element={<ProtectedRoute component={<BannerImage />} />}
                />
                <Route
                  path="/payout-request"
                  element={<ProtectedRoute component={<PayoutRequest />} />}
                />
              </Route>
            </>
          )}

          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </SnackbarProvider>
    </>
  );
}

export default App;
