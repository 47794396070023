import Login from '../../pages/auth/Login'

const Auth = () => {
  return (
    <>
      <div className="login_main d-flex gap-5 ">
        <div className="login_logo">
          <div className="login_div">
            {/* <div className="logo_img"> */}
              <img src="/images/mw_logo_2.png"  className="login-logo" alt="logo.white" />
            {/* </div> */}
            
          </div>
        </div>
        <div className="login_form_wrapper">
          <div className="login_form">
            
            <Login/>
       
            
          </div>
      </div>
      </div>
    </>
  );
};

export default Auth;
