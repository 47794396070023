import { Table, Form, Pagination, InputGroup } from "react-bootstrap";
import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { Mcontext } from "../../context/Mcontext";
import { CgSearch } from "react-icons/cg";
import { formatDate } from "../helper/commonHelper";
import Loader from "../Loader";

const CheckinHistory = () => {
  let { userid, venueid } = useParams();
  const { adminState, getUserCheckins, getSingleVenueInfo } =
    useContext(Mcontext);
  const { adminError, totalUsers } = adminState;

  const [loading, setLoading] = useState(false);
  const [boxType, setBoxType] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [checkins, setCheckins] = useState([]);
  const [userSortBy, setUserSortBy] = useState("");
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (userid) {
      setBoxType("user");
      loadCheckins("user");
    }
    if (venueid) {
      setBoxType("venue");
      loadCheckins("venue");
    }
  }, [currentPage, userSortBy, search]);

  const loadCheckins = async (type) => {
    if (type == "user") {
      const checkinRes = await getUserCheckins(
        userid,
        currentPage,
        search,
        userSortBy
      );
      if (checkinRes?.success) {
        setLoading(false);
        setCheckins(checkinRes?.success_res?.checkins);
        setTotalPage(checkinRes?.success_res?.totalPages);
      }
    }
    if (type == "venue") {
      const checkinRes = await getSingleVenueInfo(
        venueid,
        currentPage,
        search,
        userSortBy
      );
      if (checkinRes?.success) {
        setCheckins(checkinRes?.success_res?.newUsers);
        setTotalPage(checkinRes?.success_res?.totalPages);
      }
    }
  };

  const handleSortChange = (event) => {
    setUserSortBy(event.target.value);
    setCurrentPage(1);
  };

  const handleSearch = (e) => setSearch(e.target.value);

  const changeCurrentPage = (pageType) => {
    if (pageType === "prev") {
      if (currentPage === 1) {
        alert("No Page Exist Before 1");
      } else {
        setCurrentPage(currentPage - 1);
      }
    }

    if (pageType === "next") {
      if (currentPage === totalPage) {
        alert("No Page Exist After ");
      } else {
        setCurrentPage(currentPage + 1);
      }
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className="mt-5 main_div_border">
        <h5 className="main_heading_box">Check Ins</h5>
        <div className="main_div_sortby_search">
          <div className="search_sort_div">
            <div className="search_bar">
              <InputGroup className="mb-3 posrel">
                <div className="search_icon">
                  <CgSearch color="var(--primary)" size={20} />
                </div>
                <Form.Control
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="basic-addon1"
                  onChange={handleSearch}
                />
              </InputGroup>
            </div>
            <div className="sortby_select">
              <Form.Select
                aria-label="Default select example"
                onChange={handleSortChange}
                value={userSortBy}
              >
                <option value="">Sort By</option>
                {boxType === "user" && (
                  <>
                    <option value="dateDsc">Date (Newest First)</option>
                    <option value="dateAsc">Date (Oldest First)</option>
                    <option value="venueNameAsc">Venue Name (A-Z)</option>
                    <option value="venueNameDsc">Venue Name (Z-A)</option>
                  </>
                )}
                {boxType === "venue" && (
                  <>
                    <option value="dateDsc">Date (Newest First)</option>
                    <option value="dateAsc">Date (Oldest First)</option>
                    <option value="nameAsc">Name (A-Z)</option>
                    <option value="nameDsc">Name (Z-A)</option>
                  </>
                )}
              </Form.Select>
            </div>
          </div>
        </div>

        <div className="user_panel ">
          <Table responsive>
            <thead>
              <tr>
                {boxType === "user" && (
                  <>
                    <th>Venue Poster</th>
                    <th>Venue Name</th>
                    <th>Venue Type</th>
                    <th>Venue Address</th>
                  </>
                )}
                {boxType === "venue" && <th>User Name</th>}
                <th>Date</th>
              </tr>
            </thead>

            <tbody>
              {checkins?.length === 0 && (
                <tr>
                  <td colSpan={boxType === "user" ? 5 : 2}>No Checkins!</td>
                </tr>
              )}
              {checkins &&
                checkins.map((singleCheckin, index) => (
                  <tr key={index}>
                    {boxType === "user" && (
                      <>
                        <td>
                          <img
                            src={
                              singleCheckin?.venueId?.venuePhotos &&
                              singleCheckin?.venueId?.venuePhotos[0]?.path
                            }
                            width={75}
                            height={75}
                          />
                        </td>
                        <td>{singleCheckin?.venueId?.title}</td>
                        <td>{singleCheckin?.venueId?.venueType}</td>
                        <td>{singleCheckin?.venueId?.address}</td>
                      </>
                    )}
                    {boxType === "venue" && (
                      <td>{singleCheckin?.userId?.name}</td>
                    )}
                    <td>{formatDate(singleCheckin?.checkedInAt)}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
        <div className="pagination_box">
          <div className="pagination-div">
            <button
              className="btn btn-light"
              onClick={() => changeCurrentPage("prev")}
            >
              Previous
            </button>
            <span>
              {" "}
              Page {currentPage} of {totalPage}{" "}
            </span>
            <button
              className="btn btn-light"
              onClick={() => changeCurrentPage("next")}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckinHistory;
