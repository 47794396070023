import React from 'react';
import { Blocks } from 'react-loader-spinner'


const SmallLoader = () => {
    return (
        <>
            <div className='loader small-loader'>
                <Blocks
                    visible={true}
                    height="35"
                    width="35"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                />
            </div>
        </>
    )
}

export default SmallLoader