import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { BASE_URL } from '../config';
import { useSnackbar } from 'material-ui-snackbar-provider'
import * as Joi from "joi-browser";


const VenueForm = (props) => {
    const snackbar = useSnackbar();
    const { venueSelected, mode } = props;


    const [venue, setVenue] = useState({
        title: "",
        type:"email",
        venueType: "",
        address: "",
        phoneNumber: 0,
        email:"",
        city: " ",
        country: " ",
        countryCode: " ",
        desc: " ",
        postCode: 0,
        state: " ",
        deviceType:"web",
        deviceToken:null

    });

    const [errors, setErrors] = useState({});

    useEffect(() => {
        ////console.log(venueSelected);
        if (venueSelected) {
            setVenue({...venueSelected,venueType:venueSelected.type});
        }

    }, []);

    const schema = {
        //title: Joi.string().min(2).max(30).required().label("Venue Name"),
        //type: Joi.string().required().label("Type"),
        //address: Joi.string().required().label("Address"),
        //phoneNumber: Joi.Number().required().label("Phone Number"),
        // country: Joi.string().required().label("Country"),
        // state: Joi.string().required().label("State"),
        // city: Joi.string().required().label("City"),
        // zipcode: Joi.string().required().label("Zipcode"),
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setErrors({});
        setVenue({ ...venue, [name]: value });
    }

    const submitForm = async (e, type) => {
        e.preventDefault();
        ////console.log(venue);
        try {
            if (handleFormValidation()) {
                //  alert("form is valid")
                let token = localStorage.getItem('token');
                if (type === "add") {
                    const axiosRes = await axios({
                        method: "POST",
                        headers: { Authorization: `Bearer ${token}` },
                        url: `${BASE_URL}/admin/venues`,
                        data: venue
                    });
                    ////console.log(axiosRes.data);
                    if (axiosRes.data.success) {
                        snackbar.showMessage(axiosRes.data.message.toUpperCase());
                        setVenue({}); //setting the venue empty
                        props.completed();
                    }
                }
                if (type === "edit") {
                    const axiosRes = await axios({
                        method: "POST",
                        headers: { Authorization: `Bearer ${token}` },
                        url: `${BASE_URL}/admin/venues/${venue._id}`,
                        data: venue
                    });
                    ////console.log(axiosRes.data);
                    if (axiosRes.data.success) {
                        snackbar.showMessage(axiosRes.data.message.toUpperCase());
                        setVenue({}); //setting the venue empty
                        props.completed();
                    }
                }

            }

        } catch (err) {
            ////console.log("submitForm [ERROR]", err);
            if (err.response) {
                snackbar.showMessage(err.response.data.message.toUpperCase());
            } else {
                snackbar.showMessage(err.message.toUpperCase());
            }


        }


    }

    const handleFormValidation = () => {
        let formIsValid = true;
        //Name
        if (!venue["title"] || venue['title'] == "") {
            formIsValid = false;
            setErrors({ ...errors, "name": " Name is Required" })
        }

        if (typeof venue["name"] !== "undefined") {
            if (!venue["name"].match(/^[a-zA-Z]+$/)) {
                formIsValid = false;
                setErrors({ ...errors, "name": " only letters are allowed" })
            }
        }
        //type
        if (!venue["venueType"] || venue['venueType'] == "") {
            formIsValid = false;
            setErrors({ ...errors, "venueType": " Venue Type is Required" })
        }

        //address
        if (!venue["address"] || venue['address'] == "") {
            formIsValid = false;
            setErrors({ ...errors, "address": " Address is Required" })
        }

        //email validation
        // if (!venue["email"] || venue['email'] == "") {
        //     formIsValid = false;
        //     setErrors({ ...errors, "email": " email is Required" })
        // }


        //contact number
        if (!venue["phoneNumber"] || venue['phoneNumber'] == "") {
            formIsValid = false;
            setErrors({ ...errors, "phoneNumber": " Phone number is Required" })
        }

        return formIsValid;
    }

    return (
        <>
            <Form onSubmit={(e) => submitForm(e, mode)}>
                <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>Venue Name <span style={{ color: "red" }}>{errors["name"]}</span></Form.Label>
                    <Form.Control type="text" placeholder="Enter Name" name="title" value={venue.title || ""} onChange={handleChange} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicText">
                    <Form.Label>Venue Type <span style={{ color: "red" }}>{errors["venueType"]}</span></Form.Label>
                    <Form.Select aria-label="Default select example" name="venueType" value={venue.venueType || ""} onChange={handleChange}>
                        <option muted >select</option>
                        <option value="restaurant">Restaurant</option>
                        <option value="club">Club</option>
                        <option value="mall">Mall</option>
                        <option value="pub">Pub</option>
                        <option value="cafe">Cafe</option>
                        <option value="event">Event</option>
                        <option value="others">Others</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>Address <span style={{ color: "red" }}>{errors["address"]}</span></Form.Label>
                    <Form.Control type="text" placeholder="Enter Address" name="address" value={venue.address || ""} onChange={handleChange} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicName" >
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="Enter Email" name="email" value={venue.email || ""} onChange={handleChange} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicName" >
                    <Form.Label>Contact No. <span style={{ color: "red" }}>{errors["phoneNumber"]}</span></Form.Label>
                    <Form.Control type="number" placeholder="Enter Number" name="phoneNumber" value={venue.phoneNumber || ""} onChange={handleChange} />
                </Form.Group>

                <button className="form_button" variant="primary" type="submit" centered>
                    Submit
                </button>
            </Form>
        </>
    )
}

export default VenueForm