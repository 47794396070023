import axios from "axios";
import { BASE_URL } from "../../config";

//summary
export const getSummary = async () => {
  try {
    let token = localStorage.getItem("token");

    const axiosRes = await axios({
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
      url: `${BASE_URL}/admin`,
    });
    //console.log("getSummary [SUCCESS]", axiosRes.data);
    return axiosRes?.data;
  } catch (err) {
    return err;
    //console.log("getSummary [ERROR] =>", err)
  }
};

//venue functions
export const getAllVenues = async (page, sort, query) => {
  try {
    let token = localStorage.getItem("token");
    const axiosRes = await axios({
      method: "GET",
      url: `${BASE_URL}/admin/venues?page=${page}&limit=15&sort=${sort}&search=${query}`,
      headers: { Authorization: `Bearer ${token}` },
    });
    //console.log("getAllVenues [SUCCESS]", axiosRes.data);
    return axiosRes?.data;
  } catch (err) {
    //console.log("getAllVenues [ERROR]", err)
  }
};

export const getSingleVenue = async (venueid) => {
  try {
    let token = localStorage.getItem("token");
    const axiosRes = await axios({
      method: "GET",
      url: `${BASE_URL}/venues/singlevenue/${venueid}`,
      headers: { Authorization: `Bearer ${token}` },
    });
    //console.log("getSingleVenue [SUCCESS]", axiosRes.data);
    return axiosRes?.data;
  } catch (err) {
    //console.log("getSingleVenue [ERROR]", err)
  }
};

export const getSingleVenueInfo = async (venueid, page, search, sortBy) => {
  try {
    let token = localStorage.getItem("token");
    const axiosRes = await axios({
      method: "GET",
      url: `${BASE_URL}/venues/singlevenue/${venueid}?type=people&page=${page}&limit=10&search=${search}&sortBy=${sortBy}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return axiosRes?.data;
  } catch (err) {
    console.log("getSingleVenueInfo [ERROR]", err);
  }
};

// export const getSingleVenueInfo = async (venueid, page) => {
//   try {
//     let token = localStorage.getItem("token");
//     const axiosRes = await axios({
//       method: "GET",
//       url: `${BASE_URL}/venues/singlevenue/${venueid}?type=people&q=&page=${page}&limit=10`,
//       headers: { Authorization: `Bearer ${token}` },
//     });
//     //console.log("getSingleVenueInfo [SUCCESS]", axiosRes.data);
//     return axiosRes?.data;
//   } catch (err) {
//     //console.log("getSingleVenueInfo [ERROR]", err)
//   }
// }; old

export const getSingleVenueRequests = async (
  venueid,
  page,
  requestType = "all",
  sortBy,
  search
) => {
  try {
    const token = localStorage.getItem("token");
    const axiosRes = await axios.get(`${BASE_URL}/venues/${venueid}/requests`, {
      params: {
        page,
        limit: 10,
        type: requestType,
        sortBy,
        search,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return axiosRes.data;
  } catch (err) {
    console.error("Error fetching venue requests:", err);
    throw err; // Rethrow the error for the caller to handle
  }
};

export const getSingleVenueTransactions = async (
  venueid,
  page,
  sortby,
  transactionTypeSort,
  q
) => {
  try {
    let token = localStorage.getItem("token");
    const axiosRes = await axios({
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: `${BASE_URL}/admin/singlevenue/transactions/${venueid}?q=${q}&page=${page}&limit=10&sortBy=${sortby}&transactionTypeSort=${transactionTypeSort}`,
    });
    return axiosRes?.data;
  } catch (err) {
    console.log("getSingleVenueTransactions [ERROR] =>", err);
  }
};

//user functions
export const getAllUsers = async (page, sortOptions, query) => {
  try {
    let token = localStorage.getItem("token");

    const axiosRes = await axios({
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
      url: `${BASE_URL}/admin/users?page=${page}&limit=20&sortOptions=${sortOptions}&search=${query}`,
    });
    // console.log("getAllUsers [SUCCESS]", axiosRes.data);
    return axiosRes?.data;
  } catch (err) {
    //console.log("getAllUsers [ERROR] =>", err)
  }
};

export const getSingleUser = async (userid) => {
  try {
    let token = localStorage.getItem("token");

    const axiosRes = await axios({
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
      url: `${BASE_URL}/users/single/${userid}`,
    });
    //console.log("getSingleUser [SUCCESS]", axiosRes.data);
    return axiosRes?.data;
  } catch (err) {
    //console.log("getSingleUser [ERROR] =>", err)
  }
};

export const geUserTransactions = async (
  userid,
  page,
  sortBy,
  transactionTypeSort,
  q
) => {
  try {
    let token = localStorage.getItem("token");
    const axiosRes = await axios({
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: `${BASE_URL}/admin/singleuser/transactions/${userid}?q=${q}&page=${page}&limit=10&sortBy=${sortBy}&transactionTypeSort=${transactionTypeSort}`,
    });
    return axiosRes?.data;
  } catch (err) {
    console.error("Error in geUserTransactions:", err);
    throw err;
  }
};

export const getUserRequests = async (userid, page, sortBy, search) => {
  try {
    let token = localStorage.getItem("token");
    const axiosRes = await axios({
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
      url: `${BASE_URL}/admin/singleuser/requests/${userid}?page=${page}&sortBy=${sortBy}&search=${search}`,
    });
    return axiosRes?.data;
  } catch (err) {
    // Handle error
  }
};

export const getUserCheckins = async (userid, page, search, sortBy) => {
  try {
    let token = localStorage.getItem("token");
    const axiosRes = await axios({
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: `${BASE_URL}/admin/singleuser/checkins/${userid}?q&page=${page}&limit=${10}&sortBy=${sortBy}&search=${search}`,
    });
    return axiosRes?.data;
  } catch (err) {}
};

// export const getUserCheckins = async (userid, page, search, sortBy) => {
//   try {
//     let token = localStorage.getItem("token");
//     const axiosRes = await axios({
//       method: "GET",
//       headers: { Authorization: `Bearer ${token}` },
//       url: `${BASE_URL}/admin/singleuser/checkins/${userid}?q&page=${page}&limit=${10}&sortBy=${sortBy}&search=${search}`,
//     });
//     return axiosRes?.data;
//   } catch (err) {}
// };

export const getUserCurrentCheckin = async (userid) => {
  try {
    let token = localStorage.getItem("token");

    const axiosRes = await axios({
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
      url: `${BASE_URL}/admin/singleuser/currentcheckin/${userid}`,
    });
    //console.log("getUserCurrentCheckin [SUCCESS]", axiosRes.data);
    return axiosRes?.data;
  } catch (err) {
    //console.log("getUserCurrentCheckin [ERROR] =>", err)
  }
};

//pure admin functions
export const toogleVenueRequest = async (venueid, type) => {
  try {
    let token = localStorage.getItem("token");
    const axiosRes = await axios({
      method: "PUT",
      headers: { Authorization: `Bearer ${token}` },
      url: `${BASE_URL}/admin/venues/${venueid}`,
      data: { type: type },
    });
    //console.log("toogleVenueRequest", axiosRes?.data);
    return axiosRes?.data;
  } catch (err) {
    //console.log("toogleVenueRequest =>", err)
  }
};

export const toogleUserRequest = async (userid, type) => {
  try {
    let token = localStorage.getItem("token");
    const axiosRes = await axios({
      method: "PUT",
      headers: { Authorization: `Bearer ${token}` },
      url: `${BASE_URL}/admin/users/${userid}`,
      data: { type: type },
    });
    //console.log("toogleVenueRequest", axiosRes?.data);
    return axiosRes?.data;
  } catch (err) {
    //console.log("toogleVenueRequest =>", err)
  }
};

export const getRevenue = async () => {
  try {
    let token = localStorage.getItem("token");

    const axiosRes = await axios({
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
      url: `${BASE_URL}/admin/revenue`,
    });
    //console.log("getRevenue [SUCCESS]", axiosRes.data);
    return axiosRes?.data;
  } catch (err) {
    //console.log("getRevenue [ERROR] =>", err);
    return err;
  }
};

export const uploadImages = async (imageData) => {
  try {
    let token = localStorage.getItem("token");

    const axiosRes = await axios({
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      url: `${BASE_URL}/venues/upload-image`,
      data: imageData,
    });
    //console.log("uploadImages [SUCCESS]", axiosRes.data);
    return axiosRes?.data;
  } catch (err) {
    //console.log("login error in authfunction =>", err)
  }
};

export const getAllPayoutRequests = async (options = {}) => {
  const { page = 1, limit = 10, search = "", sortBy = "-createdAt" } = options;
  try {
    const token = localStorage.getItem("token");
    const axiosRes = await axios({
      method: "GET",
      url: `${BASE_URL}/admin/payouts`,
      headers: { Authorization: `Bearer ${token}` },
      params: { page, limit, search, sortBy },
    });
    return axiosRes?.data;
  } catch (err) {
    throw err;
  }
};

// export const getAllPayoutRequests = async ({
//   page = 1,
//   limit = 10,
//   search = "",
//   sortBy = "-createdAt",
// }) => {
//   try {
//     const token = localStorage.getItem("token");
//     const axiosRes = await axios({
//       method: "GET",
//       url: `${BASE_URL}/admin/payouts`,
//       headers: { Authorization: `Bearer ${token}` },
//       params: { page, limit, search, sortBy },
//     });
//     return axiosRes?.data;
//   } catch (err) {
//     throw err;
//   }
// };

// export const getAllPayoutRequests = async () => {
//   try {
//     const token = localStorage.getItem("token");
//     const axiosRes = await axios({
//       method: "GET",
//       url: `${BASE_URL}/admin/payouts`,
//       headers: { Authorization: `Bearer ${token}` },
//     });
//     return axiosRes?.data;
//   } catch (err) {
//     throw err;
//   }
// };  // keep is for working

// export const getAllPayoutRequests = async () => {
//   try {
//     let token = localStorage.getItem("token");
//     const axiosRes = await axios({
//       method: "GET",
//       url: `${BASE_URL}/admin/payouts`,
//       headers: { Authorization: `Bearer ${token}` },
//     });
//     //console.log("getAllPayoutRequests [SUCCESS]", axiosRes.data);
//     return axiosRes?.data;
//   } catch (err) {
//     //console.log("getAllPayoutRequests [ERROR]", err)
//   }
// }; old keep it

export const getPayoutRequestsByUserId = async (
  userId,
  page = 1,
  limit = 10,
  sortBy = "dateDsc" // New parameter for sorting
) => {
  try {
    const token = localStorage.getItem("token");
    let url = `${BASE_URL}/admin/payouts/user/${userId}?page=${page}&limit=${limit}&sortBy=${sortBy}`;

    const axiosRes = await axios({
      method: "GET",
      url: url,
      headers: { Authorization: `Bearer ${token}` },
    });
    return axiosRes?.data;
  } catch (err) {
    console.log("Error fetching payout requests:", err);
    throw err;
  }
};

export const payoutRequestApprove = async (withdrawlId, comments) => {
  try {
    let token = localStorage.getItem("token");
    const axiosRes = await axios({
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      url: `${BASE_URL}/admin/payout/approve/${withdrawlId}`,
      data: { comments: comments },
    });
    //console.log("payoutrequestStatusChange", axiosRes?.data);
    return axiosRes?.data;
  } catch (err) {
    //console.log("payoutrequestStatusChange =>", err)
  }
};

export const payoutRequestReject = async (withdrawlId, comments) => {
  try {
    let token = localStorage.getItem("token");
    const axiosRes = await axios({
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      url: `${BASE_URL}/admin/payout/reject/${withdrawlId}`,
      data: { comments: comments },
    });
    //console.log("payoutrequestStatusChange", axiosRes?.data);
    return axiosRes?.data;
  } catch (err) {
    //console.log("payoutrequestStatusChange =>", err)
  }
};

export const getFriendList = async (
  userid,
  page = 1,
  limit = 10,
  sortBy = ""
) => {
  try {
    let token = localStorage.getItem("token");
    const axiosRes = await axios({
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: `${BASE_URL}/admin/friends/${userid}?page=${page}&limit=${limit}&sortBy=${sortBy}`,
    });
    return axiosRes?.data;
  } catch (err) {}
};
