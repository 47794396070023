import React, { useEffect, useContext, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { BiPencil } from "react-icons/bi";
import { Col, Row } from "react-bootstrap";
import ViewPayoutHistory from "../../components/BoxComponents/ViewPayoutHistory";
import RequestHistory from "../../components/BoxComponents/RequestHistory";
import { Mcontext } from "../../context/Mcontext";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";
// import { useSnackbar } from "material-ui-snackbar-provider";
import { BASE_URL } from "../../config";
import CheckinHistory from "../../components/BoxComponents/CheckinHistory";
import TransactionHistory from "../TransactionHistory";
import Transactions from "../../components/BoxComponents/Transactions";

const VenueDetail = () => {
  // const snackbar = useSnackbar();

  let { venueid } = useParams();
  // //console.log(venueid);

  const { adminState, adminDispatch, getSingleVenue } = useContext(Mcontext);
  const { totalVenues } = adminState;

  const [loading, setLoading] = useState(false);
  const [venueDetail, setVenueDetail] = useState({});

  useEffect(() => {
    if (venueid) {
      loadSingleVenue();
    }
  }, []);

  const loadSingleVenue = async () => {
    setLoading(false);
    let venueRes = await getSingleVenue(venueid);
    // //console.log(venueRes);
    if (venueRes?.success) {
      setVenueDetail(venueRes?.success_res);
      setLoading(false);
      // toast("hello")
      // snackbar.showMessage(venueRes?.message);
    } else {
      setLoading(false);
      // snackbar.showMessage(venueRes?.message);
    }
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <div className="body-main">
        <div className="title-bar">
          <h2>
            <Link to="/">
              <HiOutlineArrowNarrowLeft />
            </Link>
            &nbsp;Venue Detail
          </h2>
        </div>
        <div className="clubbar">
          <div className="clubleft">
            <h4>{venueDetail?.title}</h4>
            <h6>{venueDetail?.type}</h6>
          </div>
          <div className="clubright">
            <button className="btn-fill">
              <BiPencil />
              Edit Venue
            </button>
          </div>
        </div>
        <div className="venuebox">
          <h5>Earnings</h5>
          <p>${venueDetail?.totalEarnings}</p>
        </div>
        <div className="venuebox">
          <h5>Banner Images</h5>
          <Row>
            {venueDetail.venuePhotos &&
              venueDetail.venuePhotos.map((singleImage, index) => {
                if (singleImage.is_featured_photo) {
                  return (
                    <Col md={3}>
                      <div className="venue-media">
                        <img src={singleImage?.path} alt="Venue Image" />
                      </div>
                    </Col>
                  );
                }
              })}
          </Row>
        </div>
        <div className="venuebox">
          <h5>Overview</h5>
          <p>{venueDetail?.desc}</p>
        </div>
        <div className="venuebox">
          <h5>Address</h5>
          <p>
            {venueDetail?.city},{venueDetail?.state},{venueDetail?.country}
          </p>
        </div>
        <div className="venuebox">
          <h5>Contact No.</h5>
          <p>{venueDetail?.phoneNumber}</p>
        </div>
        <div className="venuebox">
          <h5>Photos</h5>
          <Row>
            {venueDetail.venuePhotos &&
              venueDetail.venuePhotos.map((singleImage, index) => {
                return (
                  <Col md={3}>
                    <div className="venue-media mt-1">
                      <img src={singleImage?.path} alt="Venue Image" />
                    </div>
                  </Col>
                );
              })}
          </Row>
        </div>
        <div className="venue_detail_boxes">
          <Row>
            <Col md={12}>
              <ViewPayoutHistory />
            </Col>
            <Col md={12} className="mt-5">
              <Transactions />
            </Col>
            <Col md={12} className="mt-5">
              <CheckinHistory />
            </Col>
            <Col md={12} className="mt-5">
              <RequestHistory />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default VenueDetail;
