import React, { useContext, useEffect, useState } from "react";
import "../css/style.css";
import { BsPlusCircleFill } from "react-icons/bs";
import { Table, Tab, Tabs, Form, InputGroup } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Header from "../components/FrontendPanel/header";
import { Mcontext } from "../context/Mcontext";
import { useSnackbar } from "material-ui-snackbar-provider";
import Loader from "../components/Loader";
import FieldError from "../components/Common/FieldError";

const PayoutRequest = () => {
  const snackbar = useSnackbar();
  const {
    adminState,
    adminDispatch,
    getSummary,
    getAllPayoutRequests,
    payoutRequestApprove,
    payoutRequestReject,
  } = useContext(Mcontext);
  const { adminError, totalRequests } = adminState;

  const [loading, setLoading] = useState(false);
  const [rejectModalShow, setRejectModalShow] = useState(false);
  const [venueRequests, setVenueRequests] = useState([]);
  const [userRequests, setUserRequests] = useState([]);
  const [comments, setComments] = useState("");
  const [error, setError] = useState();
  const [status, setStatus] = useState("");
  const [withdrawlId, setWithdrawlId] = useState("");
  const [searchVenueInput, setSearchVenueInput] = useState("");
  const [searchUserInput, setSearchUserInput] = useState("");
  const [sortVenueOption, setSortVenueOption] = useState("-createdAt");
  const [sortUserOption, setSortUserOption] = useState("-createdAt");

  useEffect(() => {
    loadRequests();
  }, []);

  const loadRequests = async () => {
    // setLoading(true);
    const summaryData = await getAllPayoutRequests({
      page: 1,
      limit: 10,
      search: "",
      sortBy: "-createdAt",
    });
    console.log("🚀 ~ loadRequests ~ summaryData:", summaryData);
    if (summaryData?.success) {
      // setLoading(false);
      adminDispatch({
        type: "PAYOUTS_LOADED",
        payload: summaryData.success_res.requests,
      });
      const venueRequests = summaryData.success_res.requests.filter((request) =>
        request.type.includes("venue")
      );
      const userRequests = summaryData.success_res.requests.filter((request) =>
        request.type.includes("user")
      );
      setVenueRequests(venueRequests);
      setUserRequests(userRequests);
    } else {
      // setLoading(false);
      adminDispatch({
        type: "ERROR",
        payload: summaryData.response.data.message,
      });
    }
  };

  const toggleRequest = async () => {
    if (comments === "") {
      setError("Please provide Comments");
      setRejectModalShow(true);
      return false;
    }
    if (status === "approve") {
      const resData = await payoutRequestApprove(withdrawlId, comments);
      if (resData.success) {
        closeModal();
        loadRequests();
        snackbar.showMessage(resData.message.toUpperCase());
      }
    }
    if (status === "reject") {
      const resData = await payoutRequestReject(withdrawlId, comments);
      if (resData.success) {
        closeModal();
        loadRequests();
        snackbar.showMessage(resData.message.toUpperCase());
      }
    }
  };

  const closeModal = () => {
    setRejectModalShow(false);
    setWithdrawlId("");
    setStatus("");
    setComments("");
  };

  const handleStatus = (id, status, type) => {
    if (status === "approve") {
      setWithdrawlId(id);
      setStatus(status);
      setRejectModalShow(true);
    }
    if (status === "reject") {
      setWithdrawlId(id);
      setStatus(status);
      setRejectModalShow(true);
    }
  };

  const filterVenueRequests = () => {
    const filteredRequests = totalRequests.filter(
      (request) =>
        request.type.includes("venue") &&
        (request?.venueId.title
          .toLowerCase()
          .includes(searchVenueInput.toLowerCase()) ||
          request.bankAccountId.accountHolderName
            .toLowerCase()
            .includes(searchVenueInput.toLowerCase()) ||
          request.bankAccountId.accountNumber
            .toLowerCase()
            .includes(searchVenueInput.toLowerCase()))
    );
    let sortedRequests = [...filteredRequests];
    console.log("🚀 ~ filterVenueRequests ~ sortedRequests:", sortedRequests);
    if (sortVenueOption === "-createdAt") {
      sortedRequests = sortedRequests.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
    } else if (sortVenueOption === "createdAt") {
      sortedRequests = sortedRequests.sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      );
    } else if (sortVenueOption === "-amount") {
      sortedRequests = sortedRequests.sort((a, b) => b.amount - a.amount);
    } else if (sortVenueOption === "amount") {
      sortedRequests = sortedRequests.sort((a, b) => a.amount - b.amount);
    } else if (sortVenueOption === "venueName") {
      sortedRequests = sortedRequests.sort((a, b) =>
        a.venueId.title.localeCompare(b.venueId.title)
      );
    } else if (sortVenueOption === "-venueName") {
      sortedRequests = sortedRequests.sort((a, b) =>
        b.venueId.title.localeCompare(a.venueId.title)
      );
    } else if (sortVenueOption === "accountNumber") {
      sortedRequests = sortedRequests.sort((a, b) =>
        a.bankAccountId.accountNumber.localeCompare(
          b.bankAccountId.accountNumber
        )
      );
    } else if (sortVenueOption === "-accountNumber") {
      sortedRequests = sortedRequests.sort((a, b) =>
        b.bankAccountId.accountNumber.localeCompare(
          a.bankAccountId.accountNumber
        )
      );
    }
    setVenueRequests(sortedRequests);
  };

  const filterUserRequests = () => {
    const filteredRequests = totalRequests.filter(
      (request) =>
        request.type.includes("user") &&
        (request.userId.name
          .toLowerCase()
          .includes(searchUserInput.toLowerCase()) ||
          request.bankAccountId.accountHolderName
            .toLowerCase()
            .includes(searchUserInput.toLowerCase()) ||
          request.bankAccountId.accountNumber
            .toLowerCase()
            .includes(searchUserInput.toLowerCase()))
    );
    let sortedRequests = [...filteredRequests];
    if (sortUserOption === "-createdAt") {
      sortedRequests = sortedRequests.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
    } else if (sortUserOption === "createdAt") {
      sortedRequests = sortedRequests.sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      );
    } else if (sortUserOption === "-amount") {
      sortedRequests = sortedRequests.sort((a, b) => b.amount - a.amount);
    } else if (sortUserOption === "amount") {
      sortedRequests = sortedRequests.sort((a, b) => a.amount - b.amount);
    } else if (sortUserOption === "userName") {
      sortedRequests = sortedRequests.sort((a, b) =>
        a.userId.name.localeCompare(b.userId.name)
      );
    } else if (sortUserOption === "-userName") {
      sortedRequests = sortedRequests.sort((a, b) =>
        b.userId.name.localeCompare(a.userId.name)
      );
    } else if (sortUserOption === "accountNumber") {
      sortedRequests = sortedRequests.sort((a, b) =>
        a.bankAccountId.accountNumber.localeCompare(
          b.bankAccountId.accountNumber
        )
      );
    } else if (sortUserOption === "-accountNumber") {
      sortedRequests = sortedRequests.sort((a, b) =>
        b.bankAccountId.accountNumber.localeCompare(
          a.bankAccountId.accountNumber
        )
      );
    }
    setUserRequests(sortedRequests);
  };

  useEffect(() => {
    filterVenueRequests();
    filterUserRequests();
  }, [searchVenueInput, sortVenueOption, searchUserInput, sortUserOption]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className="verification_div">
        <h1 className="verification_text">Payout Requests</h1>
      </div>
      <div className="verification_panel payout-request">
        <Tabs
          defaultActiveKey="venue"
          id="uncontrolled-tab-example"
          className="mb-4 tabs-bg"
        >
          <Tab eventKey="venue" title="Venue">
            <div className="search_sort_div posabs search-sort">
              <div className="search_bar">
                <InputGroup className="mb-3 posrel">
                  <Form.Control
                    placeholder="Search by Venue Name, Account Holder Name, or Account Number"
                    aria-label="Search"
                    aria-describedby="basic-addon1"
                    value={searchVenueInput}
                    onChange={(e) => setSearchVenueInput(e.target.value)}
                  />
                </InputGroup>
              </div>
              <div className="sortby_select">
                <Form.Select
                  aria-label="Default select example"
                  value={sortVenueOption}
                  onChange={(e) => setSortVenueOption(e.target.value)}
                >
                  <option value="-createdAt">Date (Newest First)</option>
                  <option value="createdAt">Date (Oldest First)</option>
                  <option value="-amount">Amount (Highest First)</option>
                  <option value="amount">Amount (Lowest First)</option>
                  <option value="venueName">Venue Name (A-Z)</option>
                  <option value="-venueName">Venue Name (Z-A)</option>
                  <option value="accountNumber">Account Number (A-Z)</option>
                  <option value="-accountNumber">Account Number (Z-A)</option>
                </Form.Select>
              </div>
            </div>
            <div className="user_panel ">
              <Table responsive>
                <thead>
                  <tr>
                    <th>Venue</th>
                    <th>Venue Name</th>
                    <th>Bank Account Number</th>
                    <th>Account Holder Name</th>
                    <th>Amount</th>
                    <th>Fees</th>
                    <th>Payable Amount</th>
                    <th>Comments</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {venueRequests.length === 0 && (
                    <tr>
                      <td colSpan="9" className="text-center">
                        No Payout Requests!
                      </td>
                    </tr>
                  )}
                  {venueRequests.length > 0 &&
                    venueRequests.map((request, index) => (
                      <tr key={index}>
                        <td>
                          <div className="table_image">
                            {request?.venueId?.photo ? (
                              <img src={request?.venueId?.photo} alt="Venue" />
                            ) : (
                              "-"
                            )}
                          </div>
                        </td>
                        <td>
                          {request?.venueId ? request?.venueId?.title : "-"}
                        </td>
                        <td>{request?.bankAccountId?.accountNumber}</td>
                        <td>{request?.bankAccountId?.accountHolderName}</td>
                        <td>${request?.amount}</td>
                        <td>${request?.fees}</td>
                        <td>${request?.payableAmount}</td>
                        <td>{request?.comments}</td>
                        {request?.approvedAt ? (
                          <>
                            <td>
                              <div className="edit">
                                <button className="approve">Approved</button>
                              </div>
                            </td>
                          </>
                        ) : (
                          ""
                        )}
                        {request?.rejectedAt ? (
                          <>
                            <td>
                              <div className="edit">
                                <button
                                  onClick={() =>
                                    handleStatus(request._id, "approve")
                                  }
                                  className="approve"
                                >
                                  Approve
                                </button>
                                <button className="reject">Rejected</button>
                              </div>
                            </td>
                          </>
                        ) : (
                          ""
                        )}
                        {!request?.approvedAt && !request?.rejectedAt ? (
                          <>
                            <td>
                              <div className="edit">
                                <button
                                  onClick={() =>
                                    handleStatus(request._id, "approve")
                                  }
                                  className="approve"
                                >
                                  Approve
                                </button>
                                <button
                                  onClick={() =>
                                    handleStatus(request._id, "reject")
                                  }
                                  className="reject"
                                >
                                  Reject
                                </button>
                              </div>
                            </td>
                          </>
                        ) : (
                          ""
                        )}
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </Tab>
          <Tab eventKey="user" title="User">
            <div className="search_sort_div posabs search-sort">
              <div className="search_bar">
                <InputGroup className="mb-3 posrel">
                  <Form.Control
                    placeholder="Search by User Name, Account Holder Name, or Account Number"
                    aria-label="Search"
                    aria-describedby="basic-addon1"
                    value={searchUserInput}
                    onChange={(e) => setSearchUserInput(e.target.value)}
                  />
                </InputGroup>
              </div>
              <div className="sortby_select">
                <Form.Select
                  aria-label="Default select example"
                  value={sortUserOption}
                  onChange={(e) => setSortUserOption(e.target.value)}
                >
                  <option value="-createdAt">Date (Newest First)</option>
                  <option value="createdAt">Date (Oldest First)</option>
                  <option value="-amount">Amount (Highest First)</option>
                  <option value="amount">Amount (Lowest First)</option>
                  <option value="userName">User Name (A-Z)</option>
                  <option value="-userName">User Name (Z-A)</option>
                  <option value="accountNumber">Account Number (A-Z)</option>
                  <option value="-accountNumber">Account Number (Z-A)</option>
                </Form.Select>
              </div>
            </div>
            <div className="user_panel ">
              <Table responsive>
                <thead>
                  <tr>
                    <th>User</th>
                    <th>User Name</th>
                    <th>Bank Account Number</th>
                    <th>Account Holder Name</th>
                    <th>Amount</th>
                    <th>Fees</th>
                    <th>Payable Amount</th>
                    <th>Comments</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {userRequests.length === 0 && (
                    <tr>
                      <td colSpan="9" className="text-center">
                        No Payout Requests!
                      </td>
                    </tr>
                  )}
                  {userRequests.length > 0 &&
                    userRequests.map((request, index) => (
                      <tr key={index}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="table_image">
                              {request?.userId?.photo ? (
                                <img src={request?.userId?.photo} alt="User" />
                              ) : (
                                "-"
                              )}
                            </div>
                          </div>
                        </td>
                        <td>{request?.userId ? request?.userId?.name : "-"}</td>
                        <td>{request?.bankAccountId?.accountNumber}</td>
                        <td>{request?.bankAccountId?.accountHolderName}</td>
                        <td>${request?.amount}</td>
                        <td>${request?.fees}</td>
                        <td>${request?.payableAmount}</td>
                        <td>{request?.comments}</td>
                        {request?.approvedAt ? (
                          <>
                            <td>
                              <div className="edit">
                                <button className="approve">Approved</button>
                              </div>
                            </td>
                          </>
                        ) : (
                          ""
                        )}
                        {request?.rejectedAt ? (
                          <>
                            <td>
                              <div className="edit">
                                <button
                                  onClick={() =>
                                    handleStatus(request._id, "approve")
                                  }
                                  className="approve"
                                >
                                  Approve
                                </button>
                                <button className="reject">Rejected</button>
                              </div>
                            </td>
                          </>
                        ) : (
                          ""
                        )}
                        {!request?.approvedAt && !request?.rejectedAt ? (
                          <>
                            <td>
                              <div className="edit">
                                <button
                                  onClick={() =>
                                    handleStatus(request._id, "approve")
                                  }
                                  className="approve"
                                >
                                  Approve
                                </button>
                                <button
                                  onClick={() =>
                                    handleStatus(request._id, "reject")
                                  }
                                  className="reject"
                                >
                                  Reject
                                </button>
                              </div>
                            </td>
                          </>
                        ) : (
                          ""
                        )}
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </Tab>
        </Tabs>
      </div>
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={rejectModalShow}
        onHide={() => setRejectModalShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Are you sure you want to {status} the request?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Comments</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              onChange={(e) => setComments(e?.target?.value)}
              value={comments}
            />
            <FieldError error={error} />
          </Form.Group>
          <div className="buttons_modal">
            <button
              onClick={toggleRequest}
              className="approve"
              variant="primary"
              centered
            >
              Yes
            </button>
            <button
              onClick={closeModal}
              className="reject"
              variant="primary"
              centered
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PayoutRequest;
