import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { Mcontext } from "../../context/Mcontext";
import { Table, Form } from "react-bootstrap";
import Loader from "../Loader";
import { formatDate } from "../helper/commonHelper";
import { getPayoutRequestsByUserId } from "../../context/functions/AdminFunction";

const ViewPayoutHistory = () => {
  let { userid, venueid } = useParams();
  const { adminState, geUserTransactions, getSingleVenueTransactions } =
    useContext(Mcontext);
  const { adminError, totalUsers } = adminState;

  const [loading, setLoading] = useState(true);
  const [boxType, setBoxType] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [transactions, setTransactions] = useState([]);
  const [sortBy, setSortBy] = useState(""); // State for sorting
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    if (userid) {
      setBoxType("user");
      loadTransactions("user", currentPage, sortBy);
    }
    if (venueid) {
      setBoxType("venue");
      loadTransactions("venue");
    }
  }, [currentPage, sortBy]);

  const loadTransactions = async (type) => {
    setLoading(true);
    try {
      let transactionRes;
      if (type === "user") {
        transactionRes = await getPayoutRequestsByUserId(
          userid,
          currentPage,
          limit,
          sortBy
        );
      } else if (type === "venue") {
        transactionRes = await getSingleVenueTransactions(
          venueid,
          currentPage,
          sortBy
        );
      }
      if (transactionRes?.success) {
        setTransactions(
          transactionRes?.success_res?.requests ||
            transactionRes?.success_res?.transactions
        );
        setTotalPage(transactionRes?.success_res?.totalPages);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("loadTransactions error:", error);
      setLoading(false);
    }
  };

  const chageCurrentPage = (pageType) => {
    if (pageType === "prev") {
      if (transactions.page === 1) {
        alert("No Page Exist Before 1");
      } else {
        setCurrentPage(currentPage - 1);
      }
    }

    if (pageType === "next") {
      if (transactions.page === transactions.pages) {
        alert("No Page Exist After ");
      } else {
        setCurrentPage(currentPage + 1);
      }
    }
  };

  const handleSortChange = (event) => {
    const newSortBy = event.target.value;
    setSortBy(newSortBy);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className="mt-5 main_div_border">
        <h5 className="main_heading_box">Withdrawal</h5>
        <div className="sortby_select">
          <Form.Select
            aria-label="Default select example"
            onChange={handleSortChange}
            value={sortBy}
          >
            <option value="">Sort By</option>
            <option value="dateDsc">Date (Newest First)</option>
            <option value="dateAsc">Date (Oldest First)</option>
            <option value="high">Amount (High to Low)</option>
            <option value="low">Amount (Low to High)</option>
          </Form.Select>
        </div>
        <div className="user_panel ">
          <Table responsive>
            <thead>
              <tr>
                <th>User</th>
                <th>Bank Account Number</th>
                <th>Account Holder Name</th>
                <th>Amount</th>
                <th>Fees</th>
                <th>Payable Amount</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {transactions?.length === 0 && (
                <tr>
                  <td colSpan="7">No Payout Requests!</td>
                </tr>
              )}
              {transactions.docs?.length > 0 &&
                transactions?.docs?.map((request, index) => (
                  <tr key={index}>
                    <td>{request?.userId?.name}</td>
                    <td>{request?.bankAccountId?.accountNumber}</td>
                    <td>{request?.bankAccountId?.accountHolderName}</td>
                    <td>${request?.amount}</td>
                    <td>${request?.fees}</td>
                    <td>${request?.payableAmount}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
        <div className="pagination-div">
          <button
            className="btn btn-light"
            onClick={() => chageCurrentPage("prev")}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span>
            {" "}
            Page {transactions.page} of {transactions.pages}{" "}
          </span>
          <button
            className="btn btn-light"
            onClick={() => chageCurrentPage("next")}
            disabled={currentPage === totalPage}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default ViewPayoutHistory;
