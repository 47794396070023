import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Mcontext } from "../context/Mcontext";
import Table from "react-bootstrap/Table";

import { BASE_URL } from "../config";
import "../css/style.css";
import { BsFileEarmarkMusic, BsPlusCircleFill } from "react-icons/bs";
import { MdOutlineAttachMoney } from "react-icons/md";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import Loader from "../components/Loader";

import User from "../pages/User";
import Venue from "./Venue";

const Dashboard = () => {
  const {
    adminState,
    adminDispatch,
    authDispatch,
    authState,
    getSummary,
    getAllRequests,
    getAllUsers,
    getAllVenues,
    uploadImages,
  } = useContext(Mcontext);
  const { user } = authState;
  const { adminError, totalUsers, totalUsersCount, totalVenues } = adminState;
  const [loading, setLoading] = useState(false);
  const [totalVenuesCount, setTotalVenuesCount] = useState(0);
  const [totalIncome, setTotalIncome] = useState(0);
  const [hotLocations, sethotLocations] = useState([]);
  const [venuesEarnings, setVenuesEarnings] = useState(0);
  const [userBalances, setUserBalances] = useState(0);

  useEffect(() => {
    loadSummary();
  }, []);

  const loadSummary = async () => {
    setLoading(true);

    const summaryData = await getSummary();
    if (summaryData?.success) {
      adminDispatch({
        type: "VENUES_LOADED",
        payload: summaryData.success_res.venues,
      });
      setTotalVenuesCount(summaryData?.success_res?.totalVenues);
      setTotalIncome(summaryData?.success_res?.adminIncome);
      sethotLocations(summaryData?.success_res?.hotLocations);
      setVenuesEarnings(summaryData?.success_res?.venueIncome);
      setUserBalances(summaryData?.success_res?.userWalletBalance);
      adminDispatch({
        type: "USERS_LOADED",
        payload: summaryData?.success_res?.users,
      });
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <div className="body-main">
        <div className="title-bar">
          <h2>Dashboard </h2>
        </div>
        <section className="dashboard-boxes dashboard-components">
          <div className="boxesin Check-ins">
            <ul>
              <li>Total Users</li>
              <li>
                <AiOutlineUsergroupAdd />
              </li>
            </ul>
            <div className="boxescontent">
              <h3>{totalUsersCount}</h3>
            </div>
          </div>
          <div className="boxesin Requests">
            <ul>
              <li>Venues</li>
              <li>
                <BsFileEarmarkMusic />
              </li>
            </ul>
            <div className="boxescontent">
              <h3>{totalVenuesCount}</h3>
            </div>
          </div>
        </section>
        <section className="dashboard-boxes dashboard-components">
          <div className="boxesin Requests">
            <ul>
              <li>Admin Income</li>
              <li>
                <MdOutlineAttachMoney />
              </li>
            </ul>

            <div className="boxescontent">
              <h3>{totalIncome && totalIncome.toFixed(2)} $</h3>
            </div>
          </div>
          <div className="boxesin Requests">
            <ul>
              <li>Venue Earnings</li>
              <li>
                <MdOutlineAttachMoney />
              </li>
            </ul>
            <div className="boxescontent">
              <h3>{venuesEarnings && venuesEarnings.toFixed(2)} $</h3>
            </div>
          </div>
          <div className="boxesin Requests">
            <ul>
              <li>User Balances</li>
              <li>
                <MdOutlineAttachMoney />
              </li>
            </ul>
            <div className="boxescontent">
              <h3>{userBalances} $</h3>
            </div>
          </div>
        </section>
        <div>
          <h4 className="maintitle">Hot Locations</h4>
          <Table responsive>
            <thead>
              <tr>
                <th>City Name</th>
                <th>Count</th>
              </tr>
            </thead>
            <tbody>
              {hotLocations.map((location, index) => (
                <tr key={index}>
                  <td>{location?.cityName}</td>
                  <td>{location?.count}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        {/* <div className="hot-locations">

          <h3>Top Hot Locations</h3>

          <ul>
            
            {
              hotLocations.map((location,i)=>(
            <li key={i} >
              <span>{i + 1}.</span>
              {location.cityName}
            </li>
              ))
            }
            
          </ul>
          </div> */}

        <Venue isDashboard={true} />
        <User isDashboard={true} />
      </div>
    </>
  );
};

export default Dashboard;
