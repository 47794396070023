import axios from "axios";
import {useNavigate} from 'react-router-dom'
import { BASE_URL } from "../../config";


export const login = async (userinfo) => {
    try {
        const axiosRes = await axios({
          method: "POST",
          url: `${BASE_URL}/admin/get-admin`,
          data: userinfo,
        });
        //console.log("login [SUCCESS]",axiosRes.data);
        return axiosRes?.data;
    } catch (err) {
        //console.log("login [ERROR]", err);
        return err;
    }
}



export const refreshUser = async() =>{
    try {
      let token = localStorage.getItem('token');
      if(!token){
         //console.log("No token found logging out");
          return false;
      }
      const axiosRes = await axios({
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
        url: `${BASE_URL}/venues/single`,
      });
      if(axiosRes.data.success){
        //console.log("refreshUser [SUCCESS]",axiosRes.data.success_res);
        return axiosRes?.data;
      }else{
        return false;
      }
  } catch (err) {
      //console.log("refreshUser [ERROR]", err)
  }
  }

export const logout = async () => {
    try {
        localStorage.removeItem('token');
    } catch (err) {
        //console.log("logout [ERROR]", err)
    }
}