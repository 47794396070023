import React, { useState, useEffect, useContext } from 'react';
import { Mcontext } from '../context/Mcontext';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import Loader from '../components/Loader';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { useSnackbar } from 'material-ui-snackbar-provider'
import { BASE_URL } from '../config';
import { BsPencil, BsPlusCircleFill } from "react-icons/bs";
import { toast } from 'react-toastify';

import PhoneInput from 'react-phone-number-input'

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


let currentImage = "";

const SubAdmin = () => {
    const snackbar = useSnackbar();

    const { authState, adminState, adminDispatch, getSummary } = useContext(Mcontext);
    const { user } = authState;
    const { adminError, totalUsers, totalVenues } = adminState;

    const [modalShow, setModalShow] = useState(false);
    const [createModalShow, setCreateModalShow] = useState(false);
    const [editModalShow, setEditModalShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [bannerImages, setBannerImages] = useState([]);
    const [bannerFile, setbannerFile] = useState();
    const [subAdmins, setSubAdmins] = useState([])
    const [bannerData, setBannerData] = useState({
        title: "",
        venueid: ""
    })
    const [editingSubAdminId, setEditingSubAdminId] = useState(null);

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [phone, setPhone] = useState('')
    const [name, setName] = useState('')

    useEffect(() => {
        loadSubAdmins();
    }, [])


    const loadSubAdmins = async () => {
        try {
            setLoading(true);
            let token = localStorage.getItem("token");

            const axiosRes = await axios({
                method: "GET",
                headers: { Authorization: `Bearer ${token}` },
                url: `${BASE_URL}/admin/subadmins`,
            });

            console.log(axiosRes, "axiosRes")
            setLoading(false);
            //console.log("loadImages [SUCCESS]", axiosRes.data);
            setSubAdmins(axiosRes.data)

        } catch (err) {
            setLoading(false);

            snackbar.showMessage(err.response.data.message);

        }
    }

    // const addBannerImage = async (value) => {
    //     try {
    //         let token = localStorage.getItem("token");

    //         const axiosRes = await axios({
    //             method: "POST",
    //             headers: { Authorization: `Bearer ${token}` },
    //             url: `${BASE_URL}/admin/banner`,
    //             data: value
    //         });
    //         //console.log("toogleVenueRequest", axiosRes?.data);
    //         snackbar.showMessage(axiosRes.data.message.toUpperCase())
    //         loadSubAdmins();

    //     } catch (err) {
    //         //console.log("addBannerImage [ERROR]", err);
    //         snackbar.showMessage("IMAGE UPLOAD FAILED")

    //     }finally{

    //     }
    // }

    const updateSubAdmin = async(e,id)=>{
                // e.preventDefault();
           
        // let token = localStorage.getItem("token");

        // const dataToSend = { name, phone, email }
        // const axiosRes = await axios({
        //     method: "PATCH",
        //     headers: { Authorization: `Bearer ${token}` },
        //     url: `${BASE_URL}/admin/subadmin/${id}`,
        //     dataToSend
        // });

        // toast("Sub Admin Updated Successfully")
        // setName("");
        // setPhone("");
        // setEmail("");
        // // setPassword("");
        

        // setCreateModalShow(false);
        // loadSubAdmins()
        // //console.log("deleteBannerImage", axiosRes?.data);
        // snackbar.showMessage(axiosRes.data.message.toUpperCase())
        // loadSubAdmins();
        e.preventDefault();
        if (!id) {
          return;
        }
    
        try {

        let token = localStorage.getItem("token");

      const dataToSend = { name, phone, email };
      const axiosRes = await axios({
        method: "PATCH", // Use PATCH for updating
        headers: { Authorization: `Bearer ${token}` },
        url: `${BASE_URL}/admin/subadmin/${id}`,
        data: dataToSend,
      });

      toast("Sub Admin Updated Successfully");

      // Clear the form fields
      setName("");
      setPhone("");
      setEmail("");

      // Close the edit modal
      setEditModalShow(false);
       // Reload the sub-admins list
       loadSubAdmins();
    } catch (err) {
      console.log("Error updating sub-admin:", err.message);
      toast("Error updating sub-admin");
    }

    }

    const deleteSubAdmin = async (id) => {
        try {
            if (window.confirm("Are you sure want to Delete This Sub Admin") === true) {
                let token = localStorage.getItem("token");

                const axiosRes = await axios({
                    method: "DELETE",
                    headers: { Authorization: `Bearer ${token}` },
                    url: `${BASE_URL}/admin/subadmin/${id}`,

                });
                snackbar.showMessage(axiosRes.data.message.toUpperCase())
                loadSubAdmins();
            }

        } catch (err) {
            snackbar.showMessage("SUBADMIN DELETION FAILED")
            //console.log("deleteBannerImage [ERROR]", err);
        }
    }

    const openImageModal = (imgSrc) => {

        currentImage = imgSrc;
        setModalShow(true)
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBannerData({ ...bannerData, [name]: value });

    }

    const handleImageUpload = (e) => {
        ////console.log(e.target.files[0])
        setbannerFile(e.target.files[0]);
    }

    const submitForm = async (e) => {
        e.preventDefault();

        try {
            let token = localStorage.getItem("token");

            const dataToSend = { name, phone, email, password }
            // try{
                const axiosRes = await axios({
                method: "POST",
                headers: { Authorization: `Bearer ${token}` },
                url: `${BASE_URL}/admin/create-sub-admin`,
                data: dataToSend
            });
        
            toast("Sub Admin Created Successfully")
            setName("");
            setPhone("");
            setEmail("");
            setPassword("");

            setCreateModalShow(false);
            loadSubAdmins()

        } catch (err) {
            console.log("erooooooooooooorr")
            toast(err.response.data.message)

        }

    }



    if (loading) { return <Loader /> }
    return (
        <>
            <ToastContainer />
            <div className="venue">
                <h1 className="maintitle">Sub Admins</h1>
                <div>
                    <button className="btnblack_new" onClick={() => setCreateModalShow(true)}> <BsPlusCircleFill size='24' />&nbsp; Add Sub Admin</button>
                </div>
            </div>


            <div className='user_panel'>
                <Table responsive>
                    <thead>
                        <tr>
                            <th> Name</th>
                            <th>Email</th>
                            <th>Phone Number</th>
                            <th>Options</th>

                            <th>   </th>
                        </tr>
                    </thead>
                    <tbody>

                        {subAdmins && subAdmins.map((details, index) => (
                            <>

                                <tr key={details._id}>
                                    <td>
                                        <div className='banner_img'>

                                            <td>{details.name}</td>
                                            {/* <div >
                                                <Link  onClick={() => openImageModal(image.imageUrl)}>
                                                <img  className='crd_img' src={image.imageUrl} alt='venue doc' />

                                                </Link>
                                            </div> */}
                                            {/* <a onClick={() => openImageModal(image.imageUrl)}>View Image</a> */}


                                            {/* <p>{`${BASE_URL}/`+venue.docVerify}</p> */}

                                        </div>
                                    </td>
                                    <td>{details.email}</td>
                                    <td>{details.phone}</td>


                                    <td>
                  <div className="edit banner_img">
                    {/* Pass the sub-admin's ID to setEditingSubAdminId */}
                    <button
                      onClick={() => {
                        setEditingSubAdminId(details._id);
                        setEditModalShow(true);
                      }}
                      className="reject"
                    >
                      Update
                    </button>
                  </div>
                </td>

                                    <td><div className="edit banner_img">
                                        {/* <button onClick={() => setEditModalShow(true)} className="approve">Edit</button> */}
                                        <button onClick={() => deleteSubAdmin(details._id)} className="reject">Delete</button>
                                    </div></td>
                                </tr>

                            </>
                        ))

                        }

                    </tbody>


                </Table>
            </div>

            {/* view image modal */}
            <Modal
                show={modalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header onClick={(() => setModalShow(false))} closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {/* Banner Image */}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <p>{props.img}</p> */}
                    <div className='modal_image'>
                        <img src={currentImage} /> </div>
                </Modal.Body>
            </Modal>

            {/* add image modal */}
            <Modal
                show={createModalShow}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header onClick={(() => setCreateModalShow(false))} closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add Sub Admin
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <p>{props.img}</p> */}
                    <Form onSubmit={(e) => submitForm(e)} encType="multipart/form-data">
                        <Form.Group className="mb-3" controlId="formBasicName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter Name" name="banner" onChange={(e) => setName(e.target.value)} />
                            {/* <Form.Label>Banner Image</Form.Label>
                            <Form.Control type="file" placeholder="select banner image" name="banner" onChange={handleImageUpload} /> */}
                        </Form.Group>



                        <Form.Group className="mb-3" controlId="formBasicName">
                            <Form.Label>Email </Form.Label>
                            <Form.Control type="text" placeholder="Enter Email" name="email" onChange={(e) => setEmail(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicName">
                            <Form.Label>Phone </Form.Label>
                            {/* <Form.Control type="number" placeholder="Enter Number" name="phone" onChange={(e) => setPhone(e.target.value)} /> */}
                            <PhoneInput
                      defaultCountry="SA"
                      
                      onChange={(phoneNumber) => setPhone(phoneNumber)}
                      placeholder="Enter your Phone Number"
                    />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicName">
                            <Form.Label>Password </Form.Label>
                            <Form.Control type="password" placeholder="Enter Password" name="password" onChange={(e) => setPassword(e.target.value)} />
                        </Form.Group>



                        {/* <Form.Group className="mb-3" controlId="formBasicText">
                    <Form.Label>Venue</Form.Label>
                    <Form.Select aria-label="Default select example" name="venueid"  onChange={handleChange}>
                        <option muted >select</option>
                        {
                            totalVenues && totalVenues.map((venue,i)=>(
                                <option value={venue._id} >{venue.title}</option>
                            ))
                        }
                    </Form.Select>
                </Form.Group>  */}

                        <button className="form_button" variant="primary" type="submit" centered>
                            Submit
                        </button>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* edit subAdmin modal */}
            <Modal
                show={editModalShow}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header onClick={(() => setEditModalShow(false))} closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Edit Sub  Admin
                                            </Modal.Title>
                </Modal.Header>
                <Modal.Body>
          <Form onSubmit={(e) => updateSubAdmin(e, editingSubAdminId)} encType="multipart/form-data">
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" placeholder="Enter Name" name="banner" onChange={(e) => setName(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Email</Form.Label>
              <Form.Control type="text" placeholder="Enter Email" name="email" onChange={(e) => setEmail(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Phone</Form.Label>
              {/* <Form.Control type="number" placeholder="Enter Number" name="phone" value={phone} onChange={(e) => setPhone(e.target.value)} /> */}
              <PhoneInput
                      defaultCountry="SA"
                      
                      onChange={(phoneNumber) => setPhone(phoneNumber)}
                      placeholder="Enter your Phone Number"
                    />
            </Form.Group>
            <button className="form_button" variant="primary" type="submit" centered>
              Submit
            </button>
          </Form>
        </Modal.Body>
            </Modal>

        </>
    )
}


export default SubAdmin;