import React, { useContext } from 'react';
import { Navigate, Route } from "react-router-dom";

import { Mcontext } from '../context/Mcontext';

const ProtectedRoute = ({ auth, component, ...rest }) => {

    const {authState, authDispatch,loginAdmin,getAllUsers,logoutAdmin} = useContext(Mcontext);
    const {isLoggedIn} = authState;
    
    if (!isLoggedIn) {
        //console.log("NOT LOGGED IN")
        return <Navigate to="/login" replace />;
    }
    //console.log("LOGGED IN")
    return component;

};

export default ProtectedRoute;